import Vue from 'vue'
import VueRouter from 'vue-router'
// const Vue = require('vue')
// const VueRouter = require('vue-router')

import store from '../vuex/store'

//引入nprogress
import NProgress from 'nprogress' // 进度条
import 'nprogress/nprogress.css' //这个样式必须引入

import AxiosPlugin from '../axios/axios.js'
Vue.use(AxiosPlugin, '$axios');

// import wxUtils from '../common/wxUtils.js'
// Vue.use(wxUtils)

import {
	MessageBox,
} from 'element-ui';

Vue.prototype.$confirm = MessageBox.confirm;

// import HelloWorld from '@/components/HelloWorld'
// import frame1 from '@/components/frame1'
// // import frame2 from '@/components/frame2'

// //路由懒加载
// // const HelloWorld = () => import('@/components/HelloWorld'], resolve)
// const frame2 = () => import('@/components/frame2'], resolve)


// import loginFrame from '@/components/Login/loginFrame'
// import login from '@/components/Login/login'
//登录注册
const loginFrame = resolve => require(['@/components/Login/loginFrame'], resolve)
// const Comp = resolve => require(['@/components//Comp.vue'], resolve)
const login = resolve => require(['@/components/Login/login'], resolve)
const register = resolve => require(['@/components/Login/register'], resolve)
const registerSuccess = resolve => require(['@/components/Login/registerSuccess'], resolve)
const forgotPassword = resolve => require(['@/components/Login/forgotPassword'], resolve)
// const registrationAgreement = resolve => require(['@/components/Login/registrationAgreement'], resolve)
//权限页面
const auth = resolve => require(['@/components/Login/auth'], resolve)
//主框架
// const MainFrame = resolve => require(['@/components/MainFrame'], resolve)

//找不到页面
const NotFound = resolve => require(['@/components/NotFound/NotFound'], resolve)


const StorageCenterFrame = resolve => require(['@/components/StorageCenter/StorageCenterFrame'], resolve)
//主页
const Home = resolve => require(['@/components/StorageCenter/Home/Home'], resolve)

const InWarehouse = resolve => require(['@/components/StorageCenter/clp/InWarehouse'], resolve)
const WarehouseFee = resolve => require(['@/components/StorageCenter/statement/WarehouseFee'], resolve)
const WarehouseFeeCollect = resolve => require(['@/components/StorageCenter/statement/WarehouseFeeCollect'], resolve)
const WarehouseShipments = resolve => require(['@/components/StorageCenter/shipments/WarehouseShipments'], resolve)
// const WarehouseSku = resolve => require(['@/components/StorageCenter/WarehouseSku'], resolve)
const WarehouseExchangeReturn = resolve => require(['@/components/StorageCenter/shipments/WarehouseExchangeReturn'],
	resolve)
const UnclaimedGoodsListAll = resolve => require(['@/components/StorageCenter/ExchangeReturn/UnclaimedGoodsListAll'],
	resolve)

const TransportInWh = resolve => require(['@/components/StorageCenter/Transport/TransportInWh'], resolve)
const TransportInWhEdit = resolve => require(['@/components/StorageCenter/Transport/TransportInWhEdit'], resolve)
// const TransportInWhDetList = resolve => require(['@/components/StorageCenter/Transport/TransportInWhDetList'], resolve)
const TransportInWhByToOut = resolve => require(['@/components/StorageCenter/Transport/TransportInWhByToOut'], resolve)
const TransportOutWh = resolve => require(['@/components/StorageCenter/Transport/TransportOutWh'], resolve)
const TransportOutWhEdit = resolve => require(['@/components/StorageCenter/Transport/TransportOutWhEdit'], resolve)
const TransportOutWhDetList = resolve => require(['@/components/StorageCenter/Transport/TransportOutWhDetList'],
	resolve)
const TransportInventories = resolve => require(['@/components/StorageCenter/Transport/TransportInventories'], resolve)
//预约出库-转运
const TransportPlanOutWh = resolve => require(['@/components/StorageCenter/Transport/TransportPlanOutWh'], resolve)
const TransportPlanOutWhEdit = resolve => require(['@/components/StorageCenter/Transport/TransportPlanOutWhEdit'],
	resolve)

//快速转运
const TransportFastList = resolve => require(['@/components/StorageCenter/Transport/TransportFastList'], resolve)
const TransportFastEdit = resolve => require(['@/components/StorageCenter/Transport/TransportFast/TransportFastEdit'],
	resolve)


//互易仓-版本2
const WSkuInfo = resolve => require(['@/components/StorageCenter/WSkuProduct/WSkuInfo'], resolve)
const WSkuInventories = resolve => require(['@/components/StorageCenter/WSkuProduct/WSkuInventories'], resolve)
const WSkuRecord = resolve => require(['@/components/StorageCenter/WSkuProduct/WSkuRecord'], resolve)
const WSkuSplit = resolve => require(['@/components/StorageCenter/WSkuProduct/WSkuSplit'], resolve)
const WSkuAddSuccessPage = resolve => require(['@/components/StorageCenter/WSkuProduct/WSkuAddSuccessPage'], resolve)

const WhStockList = resolve => require(['@/components/StorageCenter/WSkuProduct/WhStockList'], resolve)

const WarehouseDropShipping = resolve => require(['@/components/StorageCenter/shipments/WarehouseDropShipping'],
	resolve)

const workOrderList = resolve => require(['@/components/StorageCenter/workOrder/workOrderList'],
	resolve)
const createWorkOrder = resolve => require(['@/components/StorageCenter/workOrder/createWorkOrder'], resolve)
const createOrder = resolve => require(['@/components/StorageCenter/workOrder/createOrder'], resolve)

const ExceptionalRecord = resolve => require(['@/components/StorageCenter/ExceptionalThing/ExceptionalRecord'], resolve)

const InventoryStatistics = resolve => require(['@/components/StorageCenter/statement/InventoryStatistics'], resolve)
const InventoryStatisticsSkuGroup = resolve => require([
	'@/components/StorageCenter/statement/InventoryStatisticsSkuGroup'
], resolve)

const CargoVolumeStatisticsByDay = resolve => require([
	'@/components/StorageCenter/statement/CargoVolumeStatisticsByDay'
], resolve)

//打单明细
// const PriterOrderDetList = resolve => require(['@/components/StorageCenter/shipments/PriterOrderDetList'], resolve)

const outBill = resolve => require(['@/components/StorageCenter/statement/outBill'], resolve)
//每月订单统计
const OrderStatisticsByMon = resolve => require(['@/components/StorageCenter/statement/OrderStatisticsByMon'], resolve)

//帮助文档
// const helpDoc = resolve => require(['@/components/Login/help/helpDoc'], resolve)
//亚马逊账号 帮助文档
const amazonHelpDoc = resolve => require(['@/components/Login/help/amazonHelpDoc'], resolve)
//网站地图
// const sitemap = resolve => require(['@/components/Login/sitemap/sitemap'], resolve)

//授权页面
const AuthFrame = resolve => require(['@/components/Auth/AuthFrame'], resolve)
const UpsOauth = resolve => require(['@/components/Auth/UpsOauth'], resolve)
const UpsOauthBack = resolve => require(['@/components/Auth/UpsOauthBack'], resolve)
const UspsOauth = resolve => require(['@/components/Auth/UspsOauth'], resolve)
const TiktokAuth = resolve => require(['@/components/Auth/TiktokAuth'], resolve)
const TiktokAuthBack = resolve => require(['@/components/Auth/TiktokAuthBack'], resolve)
const TemuAuth = resolve => require(['@/components/Auth/TemuAuth'], resolve)
const SheinAuth = resolve => require(['@/components/Auth/SheinAuth'], resolve)

//亚马逊授权
const AmazonFrame = resolve => require(['@/components/Amazon/AmazonFrame'], resolve)
const AmazonAuth = resolve => require(['@/components/Amazon/AmazonAuth'], resolve)
const AmazonAuthList = resolve => require(['@/components/Amazon/AmazonAuthList'], resolve)

//亚马逊已授权列表
const amazonUserList = resolve => require(['@/components/StorageCenter/amazon/amazonUserList'], resolve)
//亚马逊订单
const amazonOrder = resolve => require(['@/components/StorageCenter/amazon/amazonOrder'], resolve)

//用户代理
const agentUser = resolve => require(['@/components/StorageCenter/agent/agentUser'], resolve)
const agentWarehouseFee = resolve => require(['@/components/StorageCenter/agent/agentWarehouseFee'], resolve)
const agentWarehouseFeeCollect = resolve => require(['@/components/StorageCenter/agent/agentWarehouseFeeCollect'],
	resolve)

//账户
//充值记录
const RechargeRecord = resolve => require(['@/components/StorageCenter/account/RechargeRecord'], resolve)
//消费记录
const expenseRecord = resolve => require(['@/components/StorageCenter/account/expenseRecord'], resolve)
//账户信息
const accountInfo = resolve => require(['@/components/StorageCenter/account/accountInfo'], resolve)
//财务账单
const BillManage = resolve => require(['@/components/StorageCenter/FinanceMana/BillManage'], resolve)
//账单首页
const BillHome = resolve => require(['@/components/StorageCenter/FinanceMana/BillHome/BillHome'], resolve)

//第三方订单
const ThirdOrder = resolve => require(['@/components/StorageCenter/Thirdparty/ThirdOrder'], resolve)
//易仓API配置
const ThirdYiCangApiConList = resolve => require([
	'@/components/StorageCenter/Thirdparty/ThirdYiCangApiConfig/ThirdYiCangApiConList'
], resolve)
//易仓库存同步
const YiCangInventorySync = resolve => require([
	'@/components/StorageCenter/Thirdparty/ThirdYiCangApiConfig/YiCangInventorySync'
], resolve)
//快递面单
const ExpressSheetList = resolve => require(['@/components/StorageCenter/ExpressSheet/ExpressSheetList'], resolve)
//快递面单明细
const ExpressSheetDetList = resolve => require(['@/components/StorageCenter/ExpressSheet/ExpressSheetDetList'], resolve)
const LgsTrackQuery = resolve => require(['@/components/StorageCenter/ExpressSheet/LgsTrackQuery'], resolve)
//未上线运单
const ExpressTrunckNotOnlineList = resolve => require([
	'@/components/StorageCenter/ExpressSheet/ExpressTrunckNotOnlineList'
], resolve)
//打印
const PrinterFrame = resolve => require(['@/printer/PrinterFrame'], resolve)
//打印-装箱单-打印箱唛
// const ShippingMark = resolve => require(['@/components/Printer/ShippingMark'], resolve)
//预约出库-转运-提货单
const whPriterTransportPlanOutList = resolve => require(['@/printer/whPriterTransportPlanOutList'], resolve)

//第三方WMS配置
const ThirdWmsConfigList = resolve => require(['@/components/StorageCenter/ThirdWmsConfig/ThirdWmsConfigList'], resolve)
const ThirdWmsAsyncInfoList = resolve => require(['@/components/StorageCenter/ThirdWmsConfig/ThirdWmsAsyncInfoList'],
	resolve)

//第三方应用授权-订单
const ThirdApplicationList = resolve => require([
	'@/components/StorageCenter/ThirdApplicationAuth/ThirdApplicationList'], resolve)
const ThirdAppOrder = resolve => require(['@/components/StorageCenter/ThirdApplicationAuth/ThirdAppOrder'], resolve)
// const ThirdAppOrderLabels = resolve => require(['@/components/StorageCenter/ThirdApplicationAuth/ThirdAppOrderLabels'], resolve)

//QA 服务管理
const QARecList = resolve => require(['@/components/StorageCenter/QA/QARecList'], resolve)
const QARecAdd = resolve => require(['@/components/StorageCenter/QA/QARecAdd'], resolve)
const QARecProgress = resolve => require(['@/components/StorageCenter/QA/QARecProgress'], resolve)
//增值服务
const ValueAddServiceList = resolve => require(['@/components/StorageCenter/QA/ValueAddServiceList'], resolve)

//客户端菜单
//所有菜单
// const allUMenus = resolve => require(['@/components/WarehouseCenter2/userMenus/allUMenus'], resolve)
//客户菜单配置
const userMenusConfig = resolve => require(['@/components/StorageCenter/userMenus/userMenusConfig'], resolve)
//子账号管理
const subUserAccount = resolve => require(['@/components/StorageCenter/userMenus/subUserAccount'], resolve)

//通知 
const NoticeList = resolve => require(['@/components/StorageCenter/NoticeMana/NoticeList'], resolve)
const NoticeDet = resolve => require(['@/components/StorageCenter/NoticeMana/NoticeDet'], resolve)

//SKU关联
const WSkuRelation = resolve => require(['@/components/StorageCenter/WSkuProduct/WSkuRelation'], resolve)
const WSkuRelationAdd = resolve => require(['@/components/StorageCenter/WSkuProduct/WSkuRelation/WSkuRelationAdd'],
	resolve)

Vue.use(VueRouter)

const routes = [{
		path: '*', //其他页面，强制跳转到找不到页面
		redirect: '/loginFrame/NotFound'
	},
	{
		path: '/',
		// name: 'auth',
		// component: auth,
		redirect: '/loginFrame/login'
	},

	{
		path: '/loginFrame',
		name: 'loginFrame',
		component: loginFrame,
		children: [{
				path: 'NotFound',
				name: 'NotFound',
				component: NotFound
			}, {
				path: 'login',
				name: 'login',
				component: login
			},
			{
				path: 'register',
				name: 'register',
				component: register
			},
			{
				path: 'registerSuccess',
				name: 'registerSuccess',
				component: registerSuccess
			},
			{
				path: 'forgotPassword',
				name: 'forgotPassword',
				component: forgotPassword
			},
			{
				path: 'auth',
				name: 'auth',
				component: auth,
			},
			// {
			// 	path: 'helpDoc',
			// 	name: 'helpDoc',
			// 	component: helpDoc,
			// },
			{
				path: 'amazonHelpDoc',
				name: 'amazonHelpDoc',
				component: amazonHelpDoc,
			},
			// {
			// 	path: 'sitemap',
			// 	name: 'sitemap',
			// 	component: sitemap,
			// },

			// {
			// 	path: 'AmazonAuth',
			// 	name: 'AmazonAuth',
			// 	component: AmazonAuth,
			// },
			// {
			// 	path: 'AmazonAuthList',
			// 	name: 'AmazonAuthList',
			// 	component: AmazonAuthList,
			// },

		]
	},

	{
		path: '/AmazonFrame',
		name: 'AmazonFrame',
		component: AmazonFrame,
		meta: {
			isWh: true
		},
		children: [{
				path: 'AmazonAuth',
				name: 'AmazonAuth',
				component: AmazonAuth,
			},
			{
				path: 'AmazonAuthList',
				name: 'AmazonAuthList',
				component: AmazonAuthList,
			},

		]
	},

	//授权页面
	{
		path: '/AuthFrame',
		name: 'AuthFrame',
		component: AuthFrame,
		children: [{
			path: 'UpsOauth',
			name: 'UpsOauth',
			component: UpsOauth
		}, {
			path: 'UpsOauthBack',
			name: 'UpsOauthBack',
			component: UpsOauthBack
		}, {
			path: 'UspsOauth',
			name: 'UspsOauth',
			component: UspsOauth
		}, {
			path: 'TiktokAuth',
			name: 'TiktokAuth',
			component: TiktokAuth
		}, {
			path: 'TiktokAuthBack',
			name: 'TiktokAuthBack',
			component: TiktokAuthBack
		}, {
			path: 'TemuAuth',
			name: 'TemuAuth',
			component: TemuAuth
		}, {
			path: 'SheinAuth',
			name: 'SheinAuth',
			component: SheinAuth
		}],
	},


	{
		path: '/StorageCenterFrame',
		name: 'StorageCenterFrame',
		component: StorageCenterFrame,
		meta: {
			isWh: true
		},

		children: [{
				path: 'Home',
				name: 'Home',
				component: Home
			},
			{
				path: 'InWarehouse',
				name: 'InWarehouse',
				component: InWarehouse,
				meta: {
					isMenu: true
				}
			},
			{
				path: 'WarehouseFee',
				name: 'WarehouseFee',
				component: WarehouseFee,
				meta: {
					isMenu: true
				}
			},
			{
				path: 'WarehouseFeeCollect',
				name: 'WarehouseFeeCollect',
				component: WarehouseFeeCollect,
				meta: {
					isMenu: true
				}
			},

			{
				path: 'WarehouseShipments',
				name: 'WarehouseShipments',
				component: WarehouseShipments,
				meta: {
					isMenu: true
				}
			},
			{
				path: 'TransportInWh',
				name: 'TransportInWh',
				component: TransportInWh,
				meta: {
					isMenu: true
				}
			},
			{
				path: 'TransportInWhEdit',
				name: 'TransportInWhEdit',
				component: TransportInWhEdit
			},
			// {
			// 	path: 'TransportInWhDetList',
			// 	name: 'TransportInWhDetList',
			// 	component: TransportInWhDetList
			// },
			{
				path: 'TransportInWhByToOut',
				name: 'TransportInWhByToOut',
				component: TransportInWhByToOut
			},
			{
				path: 'TransportOutWh',
				name: 'TransportOutWh',
				component: TransportOutWh,
				meta: {
					isMenu: true
				}
			},
			{
				path: 'TransportOutWhEdit',
				name: 'TransportOutWhEdit',
				component: TransportOutWhEdit
			},
			{
				path: 'TransportOutWhDetList',
				name: 'TransportOutWhDetList',
				component: TransportOutWhDetList
			},
			{
				path: 'TransportInventories',
				name: 'TransportInventories',
				component: TransportInventories,
				meta: {
					isMenu: true
				}
			},

			{
				path: 'TransportPlanOutWh',
				name: 'TransportPlanOutWh',
				component: TransportPlanOutWh,
				meta: {
					isMenu: true
				}
			},
			{
				path: 'TransportPlanOutWhEdit',
				name: 'TransportPlanOutWhEdit',
				component: TransportPlanOutWhEdit
			},
			{
				path: 'TransportFastList',
				name: 'TransportFastList',
				component: TransportFastList,
				meta: {
					isMenu: true
				}
			},
			{
				path: 'TransportFastEdit',
				name: 'TransportFastEdit',
				component: TransportFastEdit
			},


			{
				path: 'WarehouseExchangeReturn',
				name: 'WarehouseExchangeReturn',
				component: WarehouseExchangeReturn,
				meta: {
					isMenu: true
				}
			},

			{
				path: 'UnclaimedGoodsListAll',
				name: 'UnclaimedGoodsListAll',
				component: UnclaimedGoodsListAll,
				meta: {
					isMenu: true
				}
			},



			// {
			// 	path: 'WarehouseSku',
			// 	name: 'WarehouseSku',
			// 	component: WarehouseSku
			// },
			{
				path: 'WSkuInfo',
				name: 'WSkuInfo',
				component: WSkuInfo,
				meta: {
					isMenu: true
				}
			},
			{
				path: 'WSkuInventories',
				name: 'WSkuInventories',
				component: WSkuInventories,
				meta: {
					isMenu: true
				}
			},
			{
				path: 'WhStockList',
				name: 'WhStockList',
				component: WhStockList,
				meta: {
					isMenu: true
				}
			},

			{
				path: 'WSkuRecord',
				name: 'WSkuRecord',
				component: WSkuRecord,
				meta: {
					isMenu: true
				}
			},
			{
				path: 'WSkuSplit',
				name: 'WSkuSplit',
				component: WSkuSplit,
				meta: {
					isMenu: true
				}
			},
			{
				path: 'WSkuAddSuccessPage',
				name: 'WSkuAddSuccessPage',
				component: WSkuAddSuccessPage
			},


			{
				path: 'WarehouseDropShipping',
				name: 'WarehouseDropShipping',
				component: WarehouseDropShipping,
				meta: {
					isMenu: true
				}
			},
			// {
			// 	path: 'PriterOrderDetList',
			// 	name: 'PriterOrderDetList',
			// 	component: PriterOrderDetList
			// },

			{
				path: 'workOrderList',
				name: 'workOrderList',
				component: workOrderList,
				meta: {
					isMenu: true
				}
			},
			{
				path: 'createWorkOrder',
				name: 'createWorkOrder',
				component: createWorkOrder
			},
			{
				path: 'createOrder',
				name: 'createOrder',
				component: createOrder
			},

			{
				path: 'ExceptionalRecord',
				name: 'ExceptionalRecord',
				component: ExceptionalRecord,
				meta: {
					isMenu: true
				}
			},
			{
				path: 'InventoryStatistics',
				name: 'InventoryStatistics',
				component: InventoryStatistics,
				meta: {
					isMenu: true
				}
			},
			{
				path: 'InventoryStatisticsSkuGroup',
				name: 'InventoryStatisticsSkuGroup',
				component: InventoryStatisticsSkuGroup,
				meta: {
					isMenu: true
				}
			},
			{
				path: 'CargoVolumeStatisticsByDay',
				name: 'CargoVolumeStatisticsByDay',
				component: CargoVolumeStatisticsByDay,
				meta: {
					isMenu: true
				}
			},

			{
				path: 'outBill',
				name: 'outBill',
				component: outBill,
				meta: {
					isMenu: true
				}
			},
			{
				path: 'OrderStatisticsByMon',
				name: 'OrderStatisticsByMon',
				component: OrderStatisticsByMon,
				meta: {
					isMenu: true
				}
			},
			{
				path: 'agentUser',
				name: 'agentUser',
				component: agentUser,
				meta: {
					isMenu: true
				}
			},
			{
				path: 'agentWarehouseFee',
				name: 'agentWarehouseFee',
				component: agentWarehouseFee,
				meta: {
					isMenu: true
				}
			},
			{
				path: 'agentWarehouseFeeCollect',
				name: 'agentWarehouseFeeCollect',
				component: agentWarehouseFeeCollect,
				meta: {
					isMenu: true
				}
			},
			{
				path: 'amazonUserList',
				name: 'amazonUserList',
				component: amazonUserList,
				meta: {
					isMenu: true
				}
			},
			{
				path: 'amazonOrder',
				name: 'amazonOrder',
				component: amazonOrder,
				meta: {
					isMenu: true
				}
			},
			{
				path: 'RechargeRecord',
				name: 'RechargeRecord',
				component: RechargeRecord
			},
			{
				path: 'expenseRecord',
				name: 'expenseRecord',
				component: expenseRecord
			},
			{
				path: 'BillManage',
				name: 'BillManage',
				component: BillManage
			},
			{
				path: 'BillHome',
				name: 'BillHome',
				component: BillHome,
				meta: {
					isMenu: true
				}
			},

			{
				path: 'accountInfo',
				name: 'accountInfo',
				component: accountInfo,
				meta: {
					isMenu: true
				}
			},
			{
				path: 'ThirdOrder',
				name: 'ThirdOrder',
				component: ThirdOrder,
				meta: {
					isMenu: true
				}
			},
			{
				path: 'ThirdYiCangApiConList',
				name: 'ThirdYiCangApiConList',
				component: ThirdYiCangApiConList,
				meta: {
					isMenu: true
				}
			},
			{
				path: 'YiCangInventorySync',
				name: 'YiCangInventorySync',
				component: YiCangInventorySync,
				meta: {
					isMenu: true
				}
			},

			{
				path: 'ExpressSheetList',
				name: 'ExpressSheetList',
				component: ExpressSheetList,
				meta: {
					isMenu: true
				}
			},
			{
				path: 'ExpressSheetDetList',
				name: 'ExpressSheetDetList',
				component: ExpressSheetDetList,
				meta: {
					isMenu: true
				}
			},
			{
				path: 'ExpressTrunckNotOnlineList',
				name: 'ExpressTrunckNotOnlineList',
				component: ExpressTrunckNotOnlineList,
				meta: {
					isMenu: true
				}
			},

			{
				path: 'LgsTrackQuery',
				name: 'LgsTrackQuery',
				component: LgsTrackQuery,
				meta: {
					isMenu: true
				}
			},
			{
				path: 'ThirdWmsConfigList',
				name: 'ThirdWmsConfigList',
				component: ThirdWmsConfigList,
				meta: {
					isMenu: true
				}
			},
			{
				path: 'ThirdWmsAsyncInfoList',
				name: 'ThirdWmsAsyncInfoList',
				component: ThirdWmsAsyncInfoList,
				meta: {
					isMenu: true
				}
			},
			{
				path: 'ThirdApplicationList',
				name: 'ThirdApplicationList',
				component: ThirdApplicationList,
				// meta: {
				// 	isMenu: true //加入白名单，回调后需要跳转到此列表
				// }
			},
			{
				path: 'ThirdAppOrder',
				name: 'ThirdAppOrder',
				component: ThirdAppOrder,
				meta: {
					isMenu: true
				}
			},
			// {
			// 	path: 'ThirdAppOrderLabels',
			// 	name: 'ThirdAppOrderLabels',
			// 	component: ThirdAppOrderLabels,

			// },

			{
				path: 'QARecList',
				name: 'QARecList',
				component: QARecList,
				meta: {
					isMenu: true
				}
			},
			{
				path: 'QARecAdd',
				name: 'QARecAdd',
				component: QARecAdd
			},
			{
				path: 'QARecProgress',
				name: 'QARecProgress',
				component: QARecProgress
			},
			{
				path: 'ValueAddServiceList',
				name: 'ValueAddServiceList',
				component: ValueAddServiceList,
				meta: {
					isMenu: true
				}
			},

			{
				path: 'userMenusConfig',
				name: 'userMenusConfig',
				component: userMenusConfig,
				meta: {
					isMenu: true
				}
			},

			{
				path: 'subUserAccount',
				name: 'subUserAccount',
				component: subUserAccount,
				meta: {
					isMenu: true
				}
			},
			{
				path: 'NoticeList',
				name: 'NoticeList',
				component: NoticeList,
				meta: {
					isMenu: false
				}
			},
			{
				path: 'NoticeDet',
				name: 'NoticeDet',
				component: NoticeDet,
				meta: {
					isMenu: false
				}
			},
			{
				path: 'WSkuRelation',
				name: 'WSkuRelation',
				component: WSkuRelation,
				meta: {
					isMenu: true
				}
			},
			{
				path: 'WSkuRelationAdd',
				name: 'WSkuRelationAdd',
				component: WSkuRelationAdd,
				meta: {
					isMenu: false
				}
			},
		]
	},
	{
		path: '/PrinterFrame',
		name: 'PrinterFrame',
		component: PrinterFrame,
		// meta: {
		// 	isMenu: false
		// },
		children: [{
			path: 'whPriterTransportPlanOutList',
			name: 'whPriterTransportPlanOutList',
			component: whPriterTransportPlanOutList
		}]
	},
];

const router = new VueRouter({
	mode: 'history',
	routes: routes,
	//滚动行为
	scrollBehavior(to, from, savedPosition) {
		// return 期望滚动到哪个的位置
		if (savedPosition) { //浏览器前进后退
			return savedPosition
		} else {
			return {
				x: 0,
				y: 0
			}
		}
	}
});


// 加载进度条，简单配置
NProgress.inc(0.2)
NProgress.configure({
	easing: 'ease',
	speed: 500,
	showSpinner: false
})

const whiteList = [];
//需要鉴权的，且非菜单的添加到白名单
routes.forEach(item => {
	if (item.meta && item.meta.isWh) { //需要鉴权的
		if (item.children) {
			item.children.forEach(item2 => {
				//存在 meta，并且 isMenu为false，加入白名单
				// isMenu: true 为菜单时，可以不写meta
				// if(!!item2.meta){//有meta
				// 	if(!item2.meta.isMenu){//isMenu 为 fasle
				// 		whiteList.push(item2.name); //非菜单项
				// 	}
				// } 
				// else {
				// 	whiteList.push(item2.name);
				// }
				// 无 meta或者 isMenu为 false，都为白名单
				// isMenu 为 true 需要鉴权的菜单
				if (!(item2.meta && item2.meta.isMenu)) { //非菜单项
					whiteList.push(item2.name); //非菜单项
				}
			})
		}
	}
})

router.beforeEach((to, from, next) => {
	// console.log('beforeEach', to, from);
	// 加载进度条
	NProgress.start();
	//更新logo显示
	store.dispatch('UPDATE_SHOWCONF');
	console.log('sysShow', store.state.sysShow);

	let userInfo = store.getters.getUserInfo;

	let showTips = store.getters.getLangMsg;

	let MenusInfoKey = store.getters.getMenusInfoKey;

	console.log('whiteList', whiteList);
	console.log('MenusInfoKey', MenusInfoKey);
	//菜单权限
	// if (to.name != 'Login' && to.name != 'Home' && to.name != 'NotFound'
	// 	&& (!MenusInfoKey || MenusInfoKey.indexOf(to.name) === -1)) { //不存在
	// 	console.log('不存在 NotFound');
	// 	next({
	// 		path: '/NotFound'
	// 	})
	// }

	if (to.matched.some(record => record.meta.isWh)) { //是否需要鉴权
		if (!userInfo || !userInfo.id) { //是否登录
			Vue.prototype.$confirm(showTips.noLogin, showTips.title, {
				type: 'warning'
			}).then(() => {
				next({
					path: '/loginFrame/login',
				});
			}).catch(() => {
				next(false);
				//加载进度条结束
				// NProgress.done();
			});
			// next(false);
			//加载进度条结束
			// NProgress.done();
			return;
		}
		if ('1' !== userInfo.isWh) { //是否有仓库权限
			Vue.prototype.$confirm(showTips.noWhAuth, showTips.title, {
				type: 'warning'
			}).then(() => {
				next({
					path: '/loginFrame/auth',
				})
			}).catch(() => {
				// window.location.reload(true);
				next(false);
				//加载进度条结束
				// NProgress.done();
			});
			// NProgress.done();
			return;
		}
		let allMenus = whiteList.concat(MenusInfoKey); //白名单合并菜单项的权限
		if (!allMenus.includes(to.name)) { //是否有菜单权限
			console.log('不存在 NotFound');
			// NProgress.done();
			next({
				path: '/loginFrame/NotFound'
			})
		}
	}
	next();
	// NProgress.done();
	// 权限判断
	// //官方写法
	// if (to.matched.some(record =>
	// 		record.meta.isWh)) {
	// 	if (!userInfo || !userInfo.id) {
	// 		Vue.prototype.$confirm(showTips.noLogin, showTips.title, {
	// 			type: 'warning'
	// 		}).then(() => {
	// 			next({
	// 				path: '/loginFrame/login',
	// 			});
	// 		}).catch(() => {
	// 			next(false);
	// 			//加载进度条结束
	// 			NProgress.done();
	// 		});
	// 		// next(false);
	// 		//加载进度条结束
	// 		NProgress.done();
	// 		return;
	// 	} else {}
	// } else {
	// 	// next();
	// }

	// console.log('权限认证');

	// if (to.matched.some(record => record.meta.isWh && '1' !== userInfo.isWh)) {

	// 	Vue.prototype.$confirm("您还没有互易仓权限，去申请吗？", showTips.title, {
	// 		type: 'warning'
	// 	}).then(() => {
	// 		next({
	// 			path: '/loginFrame/auth',
	// 		})
	// 	}).catch(() => {
	// 		// window.location.reload(true);
	// 		next(false);
	// 		//加载进度条结束
	// 		NProgress.done();
	// 	});

	// } else {
	// 	next() // 确保一定要调用 next()
	// }

})

router.afterEach((to, from) => {
	NProgress.done();
	window.scrollTo(0, 0);

});

export default router