<template>
	<div class="mainTem">
		<!-- <div class="buyerHeader"><h3 class="buyerHeaderTit">{{$t('i18nn_6b1945319eb18d5f')}}</h3></div> -->
		<!-- <div style="text-align: center; margin-top: 10px;">
      <el-button-group>
        <el-button type="primary"  size="medium" style="width: 200px;">{{$t('i18nn_5b14580d813b4a45')}}</el-button>
        <el-button type="primary" plain size="medium" style="width: 200px;" @click="toPageUrl('PartnerBillSettlementDet')">{{$t('i18nn_1b613e926a1e65dd')}}</el-button>
      </el-button-group>
    </div> -->
		<div class="tableConTop">
			<el-row>
				<el-col :span="5" class="tableConTopLeft">
					<h3>
						<span class="tct_tit">{{ $t('i18nn_6b1945319eb18d5f') }}</span>
						<el-tooltip effect="dark" :content="$t('i18nn_90b7f7d6846dfdce')" placement="top">
							<el-button type="warning" circle icon="el-icon-download" size="mini" @click="exportExcel()"></el-button>
						</el-tooltip>
					</h3>
				</el-col>
				<el-col :span="19" class="tableConTopRig">
					<!-- <ul class="actionConList"> -->
					<!-- <li> -->
					<el-button type="success" @click="toCreate()" size="small" icon="el-icon-plus">
						{{$t('i18nn_e8564657bbe9ca53')}}</el-button>
					<!-- </li> -->

					<!-- <li v-if="'0'==filterData.status"> -->
					<el-button type="primary" @click="commitAction($event, null)" size="small" icon="el-icon-sell"
						v-if="'0'==filterData.status">
						{{$t('i18nn_ad4345dbaabe1479')}}</el-button>
					<!-- </li> -->
					<!-- <li v-if="''==filterData.status||'10'==filterData.status"> -->
					<el-button type="danger" @click="commitCancelAction($event, null)" size="small" icon="el-icon-sold-out"
						v-if="''==filterData.status||'10'==filterData.status">{{$t('i18nn_b4e5993f97fe04ee')}}</el-button>

					<!-- </li> -->
					<!-- <li> -->
					
					<el-dropdown size="small" @command="fileMoreAction($event)">
						<el-button type="primary" size="small" style="margin:0 5px;">
							<i class="el-icon-paperclip"></i><span>附件</span><i
								class="el-icon-arrow-down el-icon--right"></i>
						</el-button>
						<el-dropdown-menu slot="dropdown">
							<el-dropdown-item icon="el-icon-upload2" command="toBatchUplaodFile">
								批量上传附件
							</el-dropdown-item>
							
							<el-dropdown-item icon="el-icon-download" divided command="toBatchDownloadAllFile">
								批量下载全部附件
							</el-dropdown-item>
							
							<el-dropdown-item icon="el-icon-download" divided command="toBatchDownloadOutFile">
								批量下载出库附件
							</el-dropdown-item>
						</el-dropdown-menu>
					</el-dropdown>
					<!-- <el-button type="warning" @click="toBatchUplaodFile()" size="small" icon="el-icon-upload2">
						批量上传附件</el-button>
				
					<el-button type="primary" @click="toBatchDownloadAllFile()" size="small" icon="el-icon-download">
						批量下载全部附件</el-button>
						
					<el-button type="primary" @click="toBatchDownloadOutFile()" size="small" icon="el-icon-download">
						批量下载出库附件</el-button> -->
					

					<el-button type="primary" @click="toBatchEditTmsTypeAction()" size="small" icon="el-icon-edit">
						批量修改出车方式</el-button>



					<!-- </ul> -->

					<el-button type="success" circle icon="el-icon-refresh" size="small" @click="initData()">
					</el-button>
				</el-col>
			</el-row>
		</div>

		<div class="filterCon" style="">
			<div class="filterBarList">
				<!-- {{i18nFormatter()}} -->
				<el-menu :default-active="filterData.status" mode="horizontal" @select="handleSelectStatus">
					<el-menu-item :index="item.value" v-for="item in selectOption.statusList" :key="item.value">
						{{i18nFormatter(item.value)}}
					</el-menu-item>
				</el-menu>
			</div>
			<div class="filterAction">
				<!-- <ul class="actionConList">
					<li>
						<el-button type="success" @click="toCreate()" size="small" icon="el-icon-plus">
							{{$t('i18nn_e8564657bbe9ca53')}}</el-button>
					</li>
					<li>
						<el-button type="warning" @click="toBatchUplaodFile()" size="small" icon="el-icon-upload2">
							批量上传附件</el-button>
					</li>
					<li>
						<el-button type="primary" @click="toBatchDownloadOutFile()" size="small" icon="el-icon-download">
							批量下载出库附件</el-button>
					</li>
					<li v-if="'0'==filterData.status">
						<el-button type="primary" @click="commitAction($event, null)" size="small" icon="el-icon-sell">
							{{$t('i18nn_ad4345dbaabe1479')}}</el-button>
					</li>
					<li v-if="''==filterData.status||'10'==filterData.status">
						<el-button type="danger" @click="commitCancelAction($event, null)" size="small"
							icon="el-icon-sold-out">{{$t('i18nn_b4e5993f97fe04ee')}}</el-button>

					</li>
				</ul> -->
				<ul class="filterConList">
					<!-- <li>
						<span>{{ $t('i18nn_f77f473d27f48bdc') }}</span>
						<SelAgentUser ref="SelAgentUser" @change="changeAgentUser"></SelAgentUser>
					</li> -->
					<!-- <li>
						<span>{{$t('i18nn_c944a6686d996ab3')}}</span>
						<whNoSelect @changeData="changWhNo"></whNoSelect>
					</li> -->

					<li>
						<span class="">{{$t('i18nn_c944a6686d996ab3')}}</span>
						<whNoSelect @changeData="changWhNo" size="small"></whNoSelect>
					</li>
					<li>
						<span class="filterName">提货时间</span>
						<el-date-picker v-model="filterData.OutWhDateArr" type="datetimerange" align="right" unlink-panels
							range-separator="-" :start-placeholder="$t('i18nn_7d2ffc27a09ff792')"
							:end-placeholder="$t('i18nn_46f7be1133b3e2c6')" value-format="yyyy-MM-dd HH:mm:ss"
							format="yyyy-MM-dd HH:mm:ss" size="small" @change="initData()" :picker-options="pickerOptions"
							style="width: 340px">
						</el-date-picker>
					</li>

					<li>
						<span class="filterName">{{$t('Storage.invoice_list.out_car_Type')}}</span>
						<!-- <el-radio-group v-model="filterData.wh_car_tms_type" size="small" @change="initData()">
							<el-radio-button border :label="''">{{$t('i18nn_16853bda54120bf1')}}</el-radio-button>
							<el-radio-button border v-for="item in selectOption.wh_car_tms_type" :key="item.code"
								:label="item.code">{{ $Utils.i18nCodeText(item) }}</el-radio-button>
						</el-radio-group> -->
						<el-select filterable clearable v-model="filterData.wh_car_tms_type"
							:placeholder="$t('FormMsg.Please_select')" size="small" @change="initData()" style="width: 100px;">
							<el-option v-for="item in selectOption.wh_car_tms_type" :key="item.code"
								:label="$Utils.i18nCodeText(item)" :value="item.code"></el-option>
						</el-select>
					</li>

					<li>
						<span>发货编号</span>
						<el-input :placeholder="$t('FormMsg.Please_Enter')" size="small" v-model="filterData.sendNo" maxlength="50"
							:clearable="true" style="width: 200px;"></el-input>
						<el-tooltip class="" effect="dark" :content="'多发货编号搜索'" placement="top">
							<el-button icon="el-icon-s-operation" size="small" type="success" plain
								@click="openOrderInputAction()"></el-button>
						</el-tooltip>

					</li>
					<!-- <li>
						<span>{{$t('i18nn_43a3586339251494')}}</span>
						<el-date-picker
							v-model="filterData.commitDateArr"
							type="daterange"
							align="right"
							unlink-panels
							range-separator="-"
							:start-placeholder="$t('i18nn_7d2ffc27a09ff792')"
							:end-placeholder="$t('i18nn_46f7be1133b3e2c6')"
							value-format="yyyy-MM-dd"
							format="yyyy-MM-dd"
							size="small"
							@change="initData()"
							:picker-options="pickerOptions"
						></el-date-picker>
					</li> -->
					<!-- <li>
						<span>{{$t('i18nn_66f0e38b9285894b')}}</span>
						<el-radio-group v-model="filterData.wh_car_tms_type" size="small" @change="initData()">
							<el-radio-button border :label="''">{{$t('i18nn_16853bda54120bf1')}}</el-radio-button>
							<el-radio-button border v-for="item in selectOption.wh_car_tms_type" :key="item.code" :label="item.code">{{ $Utils.i18nCodeText(item) }}</el-radio-button>
						</el-radio-group>
					</li> -->
					<li>
						<span>{{ $t('i18nn_f353b1ebbdc25be4') }}</span>
						<el-input :placeholder="$t('i18nn_3ee4c9b76289e93a')" size="small" v-model="filterData.keyword"
							maxlength="50" :clearable="true" @keyup.enter.native="initData()" style="width: 250px;">
						</el-input>
					</li>
					<li>
						<el-button icon="el-icon-search" size="small" type="primary" @click="initData()"></el-button>
					</li>
					<li>
						<el-button :icon="drawerFilter?'el-icon-arrow-up':'el-icon-arrow-down'" size="small" type="primary"
							@click="openMoreFilter()">
						</el-button>
					</li>
				</ul>
			</div>
		</div>
		<!--更多筛选-->
		<!-- <el-drawer append-to-body :wrapperClosable="false" custom-class="drawerFilter" :title="$t('i18nn_c4ca802cf2230b0b')" :visible.sync="drawerFilter"
			:direction="'rtl'"> -->
		<div class="drawerFilterCon" v-show="drawerFilter">
			<ul class="drawerFilterList">
				<!-- <li>
						<span class="filterName">{{ $t('i18nn_f77f473d27f48bdc') }}</span>
						<SelAgentUser ref="SelAgentUser" @change="changeAgentUser" size="medium"></SelAgentUser>
					</li> -->
				<!-- <li>
						<span class="filterName">{{$t('i18nn_c944a6686d996ab3')}}</span>
						<whNoSelect @changeData="changWhNo" size="medium"></whNoSelect>
					</li> -->
				<li>
					<span class="">{{ $t('i18nn_f77f473d27f48bdc') }}</span>
					<SelAgentUser ref="SelAgentUser" @change="changeAgentUser" size="medium"></SelAgentUser>
				</li>
				<li>
					<span class="filterName">提交日期</span>
					<el-date-picker v-model="filterData.commitDateArr" type="daterange" align="right" unlink-panels
						range-separator="-" :start-placeholder="$t('i18nn_7d2ffc27a09ff792')"
						:end-placeholder="$t('i18nn_46f7be1133b3e2c6')" value-format="yyyy-MM-dd" format="yyyy-MM-dd" size="small"
						@change="initData()" :picker-options="pickerOptions" style="width: 220px">
					</el-date-picker>
				</li>
				<!-- <li>
					<span class="filterName">{{$t('i18nn_66f0e38b9285894b')}}</span>
					<el-radio-group v-model="filterData.wh_car_tms_type" size="small" @change="initData()">
						<el-radio-button border :label="''">{{$t('i18nn_16853bda54120bf1')}}</el-radio-button>
						<el-radio-button border v-for="item in selectOption.wh_car_tms_type" :key="item.code"
							:label="item.code">{{ $Utils.i18nCodeText(item) }}</el-radio-button>
					</el-radio-group>
				</li> -->
				<li class="filterBtnCon">
					<el-button icon="el-icon-search" size="small" type="primary" @click="queryMoreFilter()">
						{{$t('i18nn_1e7246dd6ccc5539')}}</el-button>
				</li>
			</ul>
		</div>

		<!-- </el-drawer> -->

		<!-- 多发货编号搜索显示 -->
		<div class="" style="padding: 0 10px 10px 10px;" v-if="filterData.sendNoList && filterData.sendNoList.length > 0">
			<!-- <div slot="header" class="">
				<span><span>undefined</span>;<span>{{$t('i18nn_73bbf51487ec69e9')}}</span><strong>{{filterData.sendNoList.length}}</strong>;</span>
				<el-button icon="el-icon-close" size="small" type="success" @click="clearOrderList">{{$t('i18nn_4c4e546aa3683ff5')}}</el-button>
			</div> -->

			<ul class="filterConList" style="text-align: left;">
				<el-button icon="el-icon-close" size="small" type="success" @click="clearOrderList()">
					<span>{{$t('i18nn_4c4e546aa3683ff5')}}</span>
					(<span>多发货编号搜索</span>)
					(<span>共<span>{{filterData.sendNoList.length}}</span></span>)
				</el-button>
				<!-- <li><span>{{$t('i18nn_abf9f4b8c93d641e')}}</span>;共<strong>{{filterData.sendNoList.length}}</strong></li> -->
				<li v-for="(item, index) in filterData.sendNoList" :key="index">
					<el-tag size="small" effect="plain">{{ item }}</el-tag>
				</li>
				<!-- <li>
		  		<el-button icon="el-icon-close" size="small" type="success" plain
		  			@click="clearOrderList">undefined</el-button>
		  	</li> -->
			</ul>
		</div>

		<!-- <div class="wh-filter-con">
			<div class="">
			  <span>{{$t('Storage.packing_list.status')}}</span>
				<el-radio-group v-model="filterData.status" size="medium" @change="initData()">
				  <el-radio-button border v-for="item in selectOption.statusList" :key="item.value" :label="item.value">
						{{item.label}}
					</el-radio-button>
				</el-radio-group>
			</div>
		</div> -->
		<!-- <div class="wh-filter-con">
			<div class="wh-filter-item">
				<span>{{$t('i18nn_f77f473d27f48bdc')}}</span>
				<SelAgentUser ref="SelAgentUser" @change="changeAgentUser"></SelAgentUser>
			</div>
			<div class="wh-filter-item">
				<span>{{$t('i18nn_c944a6686d996ab3')}}</span>
				<whNoSelect @changeData="changWhNo"></whNoSelect>
			</div>
			
			<div class="wh-filter-item">
				<span>{{$t('i18nn_43a3586339251494')}}</span>
				<el-date-picker
					v-model="filterData.commitDateArr"
					type="daterange"
					align="right"
					unlink-panels
					range-separator="-"
					:start-placeholder="$t('i18nn_7d2ffc27a09ff792')"
					:end-placeholder="$t('i18nn_46f7be1133b3e2c6')"
					value-format="yyyy-MM-dd"
					format="yyyy-MM-dd"
					size="small"
					@change="initData()"
					:picker-options="pickerOptions"
				></el-date-picker>
			</div>
			<div class="wh-filter-item">
				<span>{{$t('i18nn_66f0e38b9285894b')}}</span>
				<el-radio-group v-model="filterData.wh_car_tms_type" size="small" @change="initData()">
					<el-radio-button border :label="''">{{$t('i18nn_16853bda54120bf1')}}</el-radio-button>
					<el-radio-button border v-for="item in selectOption.wh_car_tms_type" :key="item.code" :label="item.code">{{ $Utils.i18nCodeText(item) }}</el-radio-button>
				</el-radio-group>
			</div>
			</div>
			<div class="wh-filter-con">
      <div class="wh-filter-item">
        <span>{{$t('Storage.invoice_list.delivery_number')}}</span>
        <el-input :placeholder="$t('FormMsg.Please_Enter')" size="small" v-model="filterData.packingNo" maxlength="50" :clearable="true" style="width: 150px;"></el-input>
      </div>
      <div class="wh-filter-item">
        <span>{{$t('i18nn_f353b1ebbdc25be4')}}</span>
        <el-input :placeholder="$t('i18nn_3ee4c9b76289e93a')" size="small" v-model="filterData.keyword" maxlength="50" :clearable="true" style="width: 150px;">
        </el-input>
      </div>
			<div class="wh-filter-item">
				<span>{{$t('i18nn_6235565b185f0725')}}</span>
				<el-input :placeholder="$t('FormMsg.Please_Enter')" size="small" v-model="filterData.workNo" maxlength="50" :clearable="true" style="width: 220px;" @clear="initData()"></el-input>
			</div>
			
      <el-button type="primary" @click="initData" size="small">{{$t('FormMsg.Search')}}</el-button>
    </div> -->

		<!-- <div class="wh-action-con">
      <el-button type="success" @click="toCreate" size="small" icon="el-icon-upload">{{$t('i18nn_c2e3d79e5e3050f0')}}</el-button>
    </div> -->

		<div class="" v-loading="loading_load" :element-loading-text="$t('tips.loading')">
			<el-table ref="multipleTable" :data="tableData" stripe :border="true" style="width: 100%" size="small"
				:max-height="$store.state.frameConHeightWh1" @selection-change="handleSelectionChange"
				@row-click="handleCurrentChange" @sort-change="sortChange">

				<el-table-column type="selection" fixed="left" align="center" width="55">
				</el-table-column>

				<el-table-column type="index" :index="$Utils.tableIndex(pagination)" fixed="left" width="50" align="center"
					:label="$t('Storage.tableColumn.no')"></el-table-column>
				<!-- <el-table-column type="selection" fixed="left" align="center" width="50" label-class-name="nodra"></el-table-column> -->

				<!-- "sendNo":"",//"发货编号",
            "recevieName":"",//"收件人名称",
            "whNo":"",//"仓库编号",
            "recevieMobile":"",//"收件人号码",
            "postCode":"",//"邮编",
            "country":"",//"国家",
            "province":"",//"省/州",
            "city":"",//"城市",
            "address1":"",//"地址一",
            "carTmsType":"",//"出车方式",
            "carPlanDate":"",//"预计到车日期",,-->

				<el-table-column prop="statusName" :label="$t('Storage.packing_list.status')">
					<template slot-scope="scope">

						<!-- <el-tag type="" v-if="'0' == scope.row.status">{{ $Utils.i18nKeyText(scope.row,'statusName') }}</el-tag>
						<el-tag type="success" v-else-if="'10' == scope.row.status">{{ $Utils.i18nKeyText(scope.row,'statusName') }}</el-tag>
						<el-tag type="warning" v-else-if="'20' == scope.row.status">{{ $Utils.i18nKeyText(scope.row,'statusName') }}</el-tag>
						<el-tag type="danger" v-else-if="'28' == scope.row.status">{{ $Utils.i18nKeyText(scope.row,'statusName') }}</el-tag>
						<el-tag type="warning" v-else-if="'30' == scope.row.status">{{ $Utils.i18nKeyText(scope.row,'statusName') }}</el-tag>
						<el-tag type="info" v-else-if="'36' == scope.row.status">{{ $Utils.i18nKeyText(scope.row,'statusName') }}</el-tag>
						<el-tag type="info" v-else>{{ $Utils.i18nKeyText(scope.row,'statusName') }}</el-tag> -->

						<div class="tag-color-grey" v-if="'0' == scope.row.status">
							{{ $Utils.i18nKeyText(scope.row,'statusName') }}
						</div>
						<div class="tag-color-blue" v-else-if="'10' == scope.row.status">
							{{ $Utils.i18nKeyText(scope.row,'statusName') }}
						</div>
						<div class="tag-color-cyan" v-else-if="'20' == scope.row.status">
							{{ $Utils.i18nKeyText(scope.row,'statusName') }}
						</div>
						<div class="tag-color-green" v-else-if="'28' == scope.row.status">
							{{ $Utils.i18nKeyText(scope.row,'statusName') }}
						</div>
						<div class="tag-color-red" v-else-if="'30' == scope.row.status">
							{{ $Utils.i18nKeyText(scope.row,'statusName') }}
						</div>
						<div class="tag-color-orange" v-else-if="'36' == scope.row.status">
							{{ $Utils.i18nKeyText(scope.row,'statusName') }}
						</div>
						<div class="tag-color-purple" v-else>
							{{ $Utils.i18nKeyText(scope.row,'statusName') }}
						</div>

					</template>
				</el-table-column>
				<!-- <el-table-column prop="workNo" :label="$t('i18nn_6235565b185f0725')"></el-table-column> -->
				<!-- <el-table-column prop="cusName" :label="$t('i18nn_29991afa9781d554')"></el-table-column> -->

				<el-table-column prop="sysOrderNo" :label="$t('i18nn_7f6c721b57ed0e7b')" min-width="100">
					<template slot-scope="scope">
						<div>
							<!-- <el-link type="primary" size="mini"
								@click="showDetList($event, scope.row, scope.$index)">
								{{ scope.row.sysOrderNo }}
							</el-link> -->

							<div class="link-copy">
								<span class="link-text" @click="showDetList($event, scope.row, scope.$index)">
									{{ scope.row.sysOrderNo }}
								</span>
								<el-tooltip class="link-copy-icon" effect="dark" :content="$t('i18nn_29f67f0644a78bf3')"
									placement="top">
									<i class="el-icon-document-copy" @click="$Utils.copyText(scope.row.sysOrderNo)"></i>
								</el-tooltip>
							</div>
						</div>
					</template>
				</el-table-column>
				<el-table-column prop="whNo" :label="$t('Storage.skuInfo.warehouse')"></el-table-column>

				<el-table-column prop="sendNo" :label="$t('Storage.invoice_list.delivery_number')">
					<template slot-scope="scope">
						<!-- <div @click="showDetList($event,scope.row)"> -->
						<!-- <span style="color:#206ce0; font-weight: bold; text-decoration: underline; cursor: pointer;"> -->
						<!-- {{ scope.row.sendNo }} -->
						<!-- </span> -->
						<!-- </div> -->
						<div>
							<!-- <el-link v-if="!scope.row.sysOrderNo" type="primary" size="mini"
								@click="showDetList($event, scope.row, scope.$index)">
								{{ scope.row.sendNo }}
							</el-link> -->
							<span>{{ scope.row.sendNo }}</span>
						</div>
					</template>
				</el-table-column>


				<!-- <el-table-column prop="whNo" :label="$t('Storage.invoice_list.warehouse_umber')"></el-table-column> -->
				<!-- <el-table-column prop="recevieName" :label="$t('Storage.DropShipping.Addressee_name')"></el-table-column>

        <el-table-column prop="recevieMobile" :label="$t('i18nn_b29bf013aaec9ce6')"></el-table-column>

        <el-table-column prop="country" :label="$t('Storage.DropShipping.country')"></el-table-column>

        <el-table-column prop="province" label="省/州"></el-table-column>
        <el-table-column prop="city" :label="$t('Storage.DropShipping.city')"></el-table-column>
        <el-table-column prop="address1" :label="$t('i18nn_8db45b615b72b7a8')"></el-table-column>
        <el-table-column prop="postCode" :label="$t('Storage.DropShipping.postalCode')"></el-table-column> -->

				<el-table-column prop="carTmsTypeName" :label="$t('Storage.invoice_list.out_car_Type')">
					<template slot-scope="scope">
						<div class="recDet">
							<div class="recItem">
								<el-tag type="warning" effect="plain" size="small" v-if="'3'==scope.row.carTmsType">
									{{ $Utils.i18nKeyText(scope.row,'carTmsTypeName') }}
								</el-tag>
								<el-tag type="success" effect="plain" size="small" v-else-if="'1'==scope.row.carTmsType">
									{{ $Utils.i18nKeyText(scope.row,'carTmsTypeName') }}
								</el-tag>
								<el-tag type="primary" effect="plain" size="small" v-else-if="'2'==scope.row.carTmsType">
									{{ $Utils.i18nKeyText(scope.row,'carTmsTypeName') }}
								</el-tag>
								<el-tag type="danger" effect="plain" size="small" v-else>{{ $Utils.i18nKeyText(scope.row,'carTmsTypeName') }}</el-tag>
							</div>
							<div class="recItem" v-if="scope.row.isFba">
								<el-tag size="mini" type="success" v-if="'1'==scope.row.isFba">
									FBA
								</el-tag>
								<el-tag size="mini" type="info" v-else-if="'0'==scope.row.isFba">
									非FBA
								</el-tag>
							</div>
							
							<div class="recItem" v-if="!!scope.row.toWhNo">

								<div class="link-copy link-copy2" v-if="'0'==scope.row.isFba">
									<span class="link-text over_ellipsis" @click="showSelfAddress($event, scope.row, scope.$index)">
										<i class="el-icon-location-outline"></i>
										<span>{{ scope.row.toWhNo }}</span>
									</span>
								</div>

								<!-- <div v-else-if="'1'==scope.row.isFba"> -->
								<!-- <span>{{scope.row.toWhNo}}</span> -->
								<div class="link-copy link-copy2" v-else-if="'1'==scope.row.isFba">
									<span class="link-text over_ellipsis" @click="showSelfAddress($event, scope.row, scope.$index)">
										<i class="el-icon-location-outline"></i>
										<span>{{ scope.row.toWhNo }}</span>
									</span>
								</div>
								<!-- </div> -->

							</div>

						</div>
					</template>
				</el-table-column>

				<!-- <el-table-column prop="" :label="'FBA'">
					<template slot-scope="scope">
						<div>
							<el-tag type="success" v-if="1==scope.row.isFba">{{ $Utils.i18nKeyText(scope.row,'isFbaName') }}</el-tag>
							<el-tag type="info"
								v-else-if="0==scope.row.isFba">{{ $Utils.i18nKeyText(scope.row,'isFbaName') }}</el-tag>
							<div v-if="1==scope.row.isFba && !!scope.row.toWhNo">
								<div class="link-copy link-copy2">
									<span class="link-text over_ellipsis" @click="showSelfAddress($event, scope.row, scope.$index)">
										<i class="el-icon-location-outline"></i>
										<span>{{ scope.row.toWhNo }}</span>
									</span>
								</div>
							</div>
						</div>
					</template>
				</el-table-column> -->


				<el-table-column prop="carPlanDate" :label="$t('Storage.invoice_list.Expected_delivery_date')" min-width="120" :sortable="'custom'">
					<template slot-scope="scope">
						<div class="red">
							{{ scope.row.carPlanDate }}
						</div>
					</template>
				</el-table-column>

				<!-- <el-table-column prop="carPlanDate" :label="$t('Storage.invoice_list.Expected_delivery_date')">
				</el-table-column> -->
				<el-table-column prop="sendOutTypeName" :label="$t('i18nn_31519f8b1453a53e')" min-width="120">
					<template slot-scope="scope">
						<!-- <div>
							{{ $Utils.i18nKeyText(scope.row,'sendOutTypeName') }}
						</div> -->
						<div class="recDet">
							<div class="recItem" v-if="scope.row.sendOutType">
								<!-- {{ $Utils.i18nKeyText(scope.row,'sendOutTypeName') }} -->
								<el-tag size="mini" type="success" v-if="'10'==scope.row.sendOutType">
									{{ $Utils.i18nKeyText(scope.row,'sendOutTypeName') }}
								</el-tag>
								<el-tag size="mini" type="warning" v-else-if="'20'==scope.row.sendOutType">
									{{ $Utils.i18nKeyText(scope.row,'sendOutTypeName') }}
								</el-tag>
								<span v-else>
									{{ $Utils.i18nKeyText(scope.row,'sendOutTypeName') }}
								</span>
							</div>
							
							<div class="recItem" v-if="scope.row.palletCount">
								<span>{{$t('i18nn_18c1c1a3ea76e9d3')}}</span>:
								<span>{{scope.row.palletCount}}</span>
							</div>
							<div class="recItem" v-if="scope.row.palletLabel">
								<span>{{$t('i18nn_f88b93f4fd02974c')}}</span>:
								<span>{{scope.row.palletLabel}}</span>
							</div>
						</div>
						
					</template>
				</el-table-column>
				
				<el-table-column prop="packRecordList" :label="'SKU'+'*'+'数量'" min-width="150">
					<template slot-scope="scope">
						<div v-if="scope.row.sendRecordDtoList" class="recDet">
							<div class="recItem" v-for="(item,index) in scope.row.sendRecordDtoList" :key="index" v-if="index<5">
								<span class="recItem-sku">{{item.goodsSku}}</span>&nbsp;*&nbsp;
								<strong class="recItem-num">{{item.sendAcount}}</strong>
							</div>
							<div v-if="scope.row.sendRecordDtoList.length>5">
								<el-link type="primary" @click="showDetList($event, scope.row, scope.$index)">
									..
									<span>{{$t('i18nn_73bbf51487ec69e9')}}</span>
									<span>&nbsp;{{scope.row.sendRecordDtoList.length}}&nbsp;</span>
									<span>{{$t('i18nn_117aa3f27e5e430c')}}</span>
								</el-link>
							</div>
						</div>
					</template>
				</el-table-column>
				<!-- <el-table-column prop="outWhDate" :label="$t('i18nn_716dcf4d38910c35')"></el-table-column> -->
				<!-- <el-table-column prop="palletCount" :label="$t('i18nn_4429a015eeb36653')"></el-table-column> -->
				<!-- <el-table-column prop="palletCount" :label="$t('i18nn_4429a015eeb36653')" min-width="120">
					<template slot-scope="scope">
						<div>
							
							<div class="recItem">
								<span>{{$t('i18nn_18c1c1a3ea76e9d3')}}</span>:
								<span>{{scope.row.palletCount}}</span>
							</div>
							<div class="recItem">
								<span>{{$t('i18nn_f88b93f4fd02974c')}}</span>:
								<span>{{scope.row.palletLabel}}</span>
							</div>
							
						</div>
					</template>
				</el-table-column> -->
				<el-table-column prop="remark" :label="$t('Storage.tableColumn.remark')">
					<template slot-scope="scope">
						<el-popover placement="top" trigger="hover">
							<div class="pre-text">{{ scope.row.remark }}</div>
							<span class="over_ellipsis" slot="reference">{{scope.row.remark}}</span>
						</el-popover>
					</template>
				</el-table-column>

				<el-table-column prop="attachments" :label="$t('Storage.packing_list.attachment')">
					<template slot-scope="scope">
						<div @click="openUploadFile(scope.row)">
							<!-- <div v-for="(item,index) in scope.row.attachments" :key="index" v-if="index<1">
                  <el-link type="primary">{{item.fileName}}</el-link>
                </div> -->
							<div v-if="scope.row.attachments">
								<el-link type="primary">
									...<span>{{$t('i18nn_73bbf51487ec69e9')}}</span><span>{{scope.row.attachments.length}}</span><span>{{$t('i18nn_7f18cb0ceb569eb0')}}</span>
								</el-link>
							</div>
						</div>
					</template>
				</el-table-column>

				<!-- <el-table-column prop="commitDate" :label="$t('i18nn_0ae081c3b8c4d4a1')"></el-table-column> -->

				<el-table-column prop="commitDate" :label="$t('i18nn_0ae081c3b8c4d4a1')" :sortable="'custom'">

				</el-table-column>

				<el-table-column prop="outWhDate" :label="'提货时间'" :sortable="'custom'">

				</el-table-column>


				<el-table-column :label="$t('Storage.tableColumn.operation')" width="280px" fixed="right" align="left">
					<template slot-scope="scope">
						<!-- <div v-if="isSel"><el-button @click="selRow($event, scope.row, scope.$index)" type="warning" size="mini" icon="el-icon-magic-stick">{{$t("FormMsg.Select")}}</el-button></div> -->
						<!-- <div v-else> -->
						<!-- <el-button @click="openEdit($event, scope.row, scope.$index)" type="warning" size="mini" icon="el-icon-edit">{{$t("FormMsg.Edit")}}</el-button> -->
						<!-- <el-button @click="delAction($event, scope.row)" type="danger" size="mini" icon="el-icon-minus">{{$t("FormMsg.Delete")}}</el-button> -->
						<!-- </div> -->
						<!-- <el-button @click="openEdit($event, scope.row, scope.$index)" type="warning" size="mini" icon="el-icon-edit">{{$t("FormMsg.Edit")}}</el-button> -->
						<div>

							<div style="margin-bottom: 5px;">
								<el-button @click="commitAction($event, scope.row, scope.$index)" type="primary" size="mini"
									icon="el-icon-sell" v-if="'0' == scope.row.status">
									{{$t('Storage.DropShipping.submit')}}</el-button>


								<el-button @click="openEdit($event, scope.row, scope.$index)" type="warning" size="mini"
									icon="el-icon-edit" v-if="'0' == scope.row.status||'10' == scope.row.status">
									{{$t("FormMsg.Edit")}}
								</el-button>


								<el-button @click="delAction($event, scope.row)" type="danger" size="mini" icon="el-icon-minus"
									v-if="'0' == scope.row.status || '90' == scope.row.status">
									{{$t("FormMsg.Delete")}}
								</el-button>
								<!-- </div> -->
								<el-button @click="commitCancelAction($event, scope.row, scope.$index)" type="danger" size="mini"
									icon="el-icon-sold-out" v-if="'10' == scope.row.status">{{$t('i18nn_b4e5993f97fe04ee')}}</el-button>
								<!-- <div style="margin-bottom: 10px;" v-if="'0' == scope.row.status"> -->


								<el-button @click="copyAction($event, scope.row, scope.$index)" type="success" size="small"
									icon="el-icon-copy-document"
									v-if="'0' == scope.row.status||'10' == scope.row.status">{{$t('i18nn_29f67f0644a78bf3')}}</el-button>

							</div>

							<div>
								<el-button @click="openOtherEdit($event, scope.row, scope.$index)" type="success" size="small"
									icon="el-icon-edit" v-if="'36' != scope.row.status">{{$t('i18nn_99b88de52e382ca4')}}</el-button>

								<el-dropdown size="small" @command="rowMoreAction($event, scope.row, scope.$index)">
									<el-button type="primary" size="small" style="margin:0 5px;">
										<span>{{$t('i18nn_c3525eb110db58aa')}}</span><i class="el-icon-arrow-down el-icon--right"></i>
									</el-button>
									<el-dropdown-menu slot="dropdown">

										<el-dropdown-item command="openUploadFile" icon="el-icon-upload2">
											{{$t('FormMsg.upload_attachment')}}
										</el-dropdown-item>
										<!-- <el-dropdown-item divided command="openOtherEdit" icon="el-icon-edit">{{$t('i18nn_99b88de52e382ca4')}}</el-dropdown-item> -->

										<el-dropdown-item divided command="openSendOutFile" icon="el-icon-paperclip"
											v-if="'30' == scope.row.status||'36' == scope.row.status">
											{{$t('i18nn_82dd2957184fa298')}}
										</el-dropdown-item>
										<el-dropdown-item divided command="openPalletFile" icon="el-icon-paperclip"
											v-if="'28' == scope.row.status||'30' == scope.row.status||'36' == scope.row.status">
											{{$t('i18nn_b3cc9e7df0c87798')}}
										</el-dropdown-item>
										<el-dropdown-item divided command="updataDestination" icon="el-icon-edit"
											v-if="'36' != scope.row.status">
											{{$t('i18nn_f4af8c42d7478c6f')}}
										</el-dropdown-item>
										<el-dropdown-item divided command="downLoadBolFile" icon="el-icon-download">
											<!-- <span>{{$t('i18nn_245883c80f181c4a')}}</span> -->
											<span>生成</span>
											BOL
										</el-dropdown-item>

										<el-dropdown-item divided command="openQARecList" icon="el-icon-service">
											{{$t('i18nn_10bc8a2b45aa5636')}}
										</el-dropdown-item>

									</el-dropdown-menu>
								</el-dropdown>
							</div>

							<!-- <div style="margin-bottom: 5px;" v-if="'10' == scope.row.status">
								<el-button @click="commitCancelAction($event, scope.row, scope.$index)" type="danger"
									size="mini" icon="el-icon-sold-out">{{$t('i18nn_b4e5993f97fe04ee')}}</el-button>
							</div> -->

							<!-- <div style="margin-bottom: 5px;">
								<el-button @click="openUploadFile($event, scope.row)" type="success" size="mini"
									icon="el-icon-upload2">{{$t('FormMsg.upload_attachment')}}</el-button>
									
								<el-button @click="updataDestination($event, scope.row)" type="primary" size="mini"
									icon="el-icon-edit">{{$t('i18nn_f4af8c42d7478c6f')}}</el-button>
							</div>

							<div style="margin-bottom: 5px;">
								<el-button @click="openSendOutFile($event, scope.row, scope.$index)" type="primary"
									size="mini" icon="el-icon-files"
									v-if="'30' == scope.row.status||'36' == scope.row.status">
									{{$t('i18nn_82dd2957184fa298')}}</el-button>
								<el-button @click="openPalletFile($event, scope.row, scope.$index)" type="primary"
									size="mini" icon="el-icon-files"
									v-if="'28' == scope.row.status||'30' == scope.row.status||'36' == scope.row.status">
									{{$t('i18nn_b3cc9e7df0c87798')}}</el-button>
							</div>

							<div style="margin-bottom: 5px;" v-if="30==scope.row.status||36==scope.row.status">
								<el-button @click="downLoadBolFile($event, scope.row)" type="success" size="mini"
									icon="el-icon-download"><span>{{$t('i18nn_245883c80f181c4a')}}</span>BOL</el-button>
							</div>

							<div style="margin-bottom: 5px;">
								<el-button @click="openQARecList($event, scope.row)"  type="primary" plain size="mini"
									icon="el-icon-service">{{$t('i18nn_10bc8a2b45aa5636')}}</el-button>
							</div> -->
							<!-- <div v-if="'0' == scope.row.status">
                <el-button @click="shipmentsAction($event, scope.row, scope.$index)" type="success" size="mini" icon="el-icon-sell">{{$t('Storage.invoice_list.Submit_instructions')}}</el-button>
              </div> -->
							<!-- <div v-if="'30' == scope.row.status||'36' == scope.row.status">
                <el-button @click="downloadBillLading($event, scope.row, scope.$index)" type="warning" size="mini" icon="el-icon-download">{{$t('Storage.invoice_list.Download_lading_bill')}}</el-button>
              </div> -->
						</div>

					</template>
				</el-table-column>
			</el-table>

			<div class="tableCon_pagination">
				<hy-page-pagination :pagination="pagination" v-on:updatePageData="getPageData"></hy-page-pagination>
			</div>
		</div>

		<!-- 发货编号输入框 -->
		<el-dialog :title="'发货编号输入'" append-to-body :close-on-click-modal="false" :visible.sync="dialogOrderInputVisible"
			width="1000px">
			<div style="">
				<h3>{{$t('i18nn_380912455c6e2f06')}}</h3>
				<el-input type="textarea" :autosize="{ minRows: 20 }" v-model="filterData.sendNoListStr" size="small" clearable
					show-word-limit maxlength="10000" :placeholder="'一行一个发货编号'" style="width: 100%;vertical-align: middle; " />
			</div>
			<div slot="footer" class="dialog-footer">
				<el-button type="primary" plain @click="dialogOrderInputVisible = false">{{ $t('i18nn_4e9fc68608c60999') }}
				</el-button>
				<el-button type="primary" @click="sureOrderInputAction()">{{$t('i18nn_b5511889be42a3da')}}</el-button>
			</div>
		</el-dialog>

		<!-- 自提单,修改弹窗 -->
		<!-- <el-dialog :close-on-click-modal="false" top="0" :title="$t('i18nn_6267f3aedf895209')"
			:visible.sync="dialogFormVisible" custom-class="myFullDialog4">

			<div>
				<WarehouseShipmentsEdit :editId="editId" :openTime="openTimeEditId" @submitSuccess="editSuccess">
				</WarehouseShipmentsEdit>
			</div>

			<div slot="footer" class="dialog-footer">
				<el-button type="primary" plain @click="dialogFormVisible = false">{{$t('FormMsg.Close')}}</el-button>
			</div>

		</el-dialog> -->

		<!-- 留言 -->
		<!-- <WarehouseShipmentsLeaveMessage :openTime="LeaveMessageOpenTime" :row="LeaveMessageOpenRow"></WarehouseShipmentsLeaveMessage> -->

		<!--查看图片-->
		<!-- <el-dialog :title="$t('FormMsg.picture')" append-to-body :close-on-click-modal="false" :visible.sync="dialogImgVisible" width="1000px" top="0">
      <div style="overflow: auto; width: 100%; height: 80%;"><img :src="imgUrlBigShow" width="auto" height="auto" /></div>
      <div slot="footer" class="dialog-footer"><el-button type="primary" plain @click="dialogImgVisible = false">{{$t('FormMsg.Close')}}</el-button></div>
    </el-dialog> -->

		<!--选择入库数据-->
		<!-- <el-dialog :title="$t('FormMsg.Select')" append-to-body :close-on-click-modal="false" :visible.sync="dialogSelVisible" width="1200px" top="0">
      <div style=""><WSkuInfo :isSel="true" :status="'1'" @selectRow="selWhGoodsData"></WSkuInfo></div>
      <div slot="footer" class="dialog-footer"><el-button type="primary" plain @click="dialogSelVisible = false">{{$t('FormMsg.Close')}}</el-button></div>
    </el-dialog> -->
		<!--  更新目的地 -->
		<TransportUpdataDestination ref="TransportUpdataDestination" :openTime="TransportUpdataAdrOpenTime"
			:row="TransportUpdataAdrRow" @success="successSubmitDestData">
		</TransportUpdataDestination>

		<!--查看明细-->
		<!-- <el-dialog :title="$t('FormMsg.details_View')" append-to-body :close-on-click-modal="false"
			:visible.sync="dialogShowDet" width="1200px" top="0"> -->
		<el-drawer :wrapperClosable="false" :title="$t('FormMsg.details_View')" append-to-body :visible.sync="dialogShowDet"
			:direction="'rtl'" size="1200px">
			<div style="">
				<WarehouseShipmentsDetList :sendId="sendId"></WarehouseShipmentsDetList>
			</div>
			<!-- <div slot="footer" class="dialog-footer">
				<el-button type="primary" plain @click="dialogShowDet = false">{{$t('FormMsg.Close')}}</el-button>
			</div> -->
		</el-drawer>
		<!-- </el-dialog> -->
		<!-- 编辑-批量-出车方式-自提-卡派-快递 -->
		<WhInShipmentsComEditBatchTmsType :openTime="editBatchTmsTypeOpenTime" :selTableData="multipleSelection"
			@success="complateSuccess"></WhInShipmentsComEditBatchTmsType>

		<!-- 编辑-预计倒车日期 -->
		<WhInShipmentsComEdit :row="editRow" :openTime="editOpenTime" @success="complateSuccess"></WhInShipmentsComEdit>

		<!-- 售后 -->
		<QAModelRecList :openTime="QAMoRecListOpenTime" :type="'20'" :relationId="QAMoRecListRelationId"
			:relationNo="QAMoRecListRelationNo" :whNo="QAMoRecListWhNo" @success="QAMoRecListSuccess"></QAModelRecList>

		<!-- 出库，打托，附件预览 -->
		<whFileView :openTime="openTimeFileList" :row="selFileListData" :title="FileListTitle" :typeCode="FileListTypeCode">
		</whFileView>

		<!--  附件批量上传 -->
		<WhShipmentsBatchUploadFile :openTime="BatchFileUploadOpenTime" :selTableData="multipleSelection"
			@success="FileUploadSuccess">
		</WhShipmentsBatchUploadFile>

		<!--  附件上传 -->
		<whFileUploadSelTypes :openTime="FileUploadOpenTime" :relationId="fileRelationId" @success="FileUploadSuccess">
		</whFileUploadSelTypes>

		<!-- excel 导出排序 -->
		<whExcelCustom :openTime="excelOpenTime" :excelHead="excelHead" :excelData="expExcelData" :pagination="{}"
			:excelName="excelName" :excelOption="excelOption" :expHttpUrl="$urlConfig.WhInShipmentsExport"
			:expHttpFilter="pageFilterData()" :noShowExpDefExcel="true" :hasFormatter="true" :formatterData="formatterData">
		</whExcelCustom>

	</div>
</template>
<script>
	import {
		getDicData
	} from '@/axios/common.js';

	import {
		WarehouseShipments_status
	} from '@/i18n/langStatus.js';
	import {
		i18nStatus
	} from '@/i18n/FormatI18n.js';

	import {
		downloadPdfBuffer
	} from '@/utils/pdfUtils.js';

	import {
		FormatTableSort
	} from '@/utils/utils.js';

	import {
		fileZipAndDownloadReName
	} from '@/utils/fileZipDownload.js';

	// import WSkuInfo from '@/components/StorageCenter/WSkuProduct/WSkuInfo.vue';
	// import WarehouseShipmentsEdit from '@/components/StorageCenter/shipments/WarehouseShipmentsEdit.vue';
	// import WarehouseShipmentsLeaveMessage from '@/components/StorageCenter/shipments/WarehouseShipmentsLeaveMessage.vue';
	import QAModelRecList from '@/components/StorageCenter/QA/QAModelRecList.vue';

	import whFileView from '@/components/StorageCenter/components/whFileView.vue';

	import WarehouseShipmentsDetList from '@/components/StorageCenter/shipments//WarehouseShipmentsDetList.vue';
	import whFileUploadSelTypes from '@/components/StorageCenter/components/whFileUploadSelTypes.vue';
	import SelAgentUser from '@/components/Common/SelAgentUser.vue';
	import whNoSelect from '@/components/StorageCenter/components/whNoSelect.vue';
	import WhInShipmentsComEditBatchTmsType from '@/components/StorageCenter/shipments/WhInShipmentsComEditBatchTmsType.vue';
	import TransportUpdataDestination from '@/components/StorageCenter/Transport/TransportUpdataDestination.vue';

	import WhInShipmentsComEdit from '@/components/StorageCenter/shipments/WhInShipmentsComEdit.vue';
	import WhShipmentsBatchUploadFile from '@/components/StorageCenter/shipments/WhShipmentsBatchUploadFile.vue';

	import whExcelCustom from '@/components/StorageCenter/components/whExcelCustom.vue';
	export default {

		components: {
			// WSkuInfo,
			// WarehouseShipmentsEdit,
			// WarehouseShipmentsLeaveMessage,
			WarehouseShipmentsDetList,
			whFileView,
			whFileUploadSelTypes,
			SelAgentUser,
			whNoSelect,
			QAModelRecList,
			TransportUpdataDestination,
			WhInShipmentsComEdit,
			WhShipmentsBatchUploadFile,
			whExcelCustom,
			WhInShipmentsComEditBatchTmsType
		},
		data() {
			return {
				//excel导出
				excelOpenTime: '',
				excelHead: [],
				expExcelData: [],
				excelName: '',
				excelOption: {},

				//批量编辑-出车方式
				// editBatchTmsTypeRow:'',
				editBatchTmsTypeOpenTime: '',

				dialogShowDet: false,
				sendId: null,

				//批量附件上传
				BatchFileUploadOpenTime: '',

				fileRelationId: '',
				FileUploadOpenTime: '',

				// LeaveMessageOpenTime:"",
				// LeaveMessageOpenRow:{},
				multipleSelection: [],

				openTimeFileList: "",
				selFileListData: "",
				FileListTitle: "",
				FileListTypeCode: "",

				drawerFilter: false,
				//目的地址
				TransportUpdataAdrOpenTime: '',
				TransportUpdataAdrRow: {},

				//编辑-预计到货日期
				editRow: '',
				editOpenTime: '',

				pickerOptions: this.$PickerDate.pickerOptions_1(),

				//售后
				QAMoRecListOpenTime: "",
				QAMoRecListRelationId: "",
				QAMoRecListRelationNo: "",
				// QAMoRecListUserId:"",
				QAMoRecListWhNo: "",

				//发货编号输入弹窗
				dialogOrderInputVisible: false,

				loading: false,

				loading_det: false,


				loading_load: false,
				tableData: [],
				//分页数据
				pagination: this.$Utils.defaultPagination(),
				selectOption: {
					// wh_weight_unit: [],
					// wh_vol_unit: [],
					// wh_goods_fee_type: [],
					// wh_size_unit: [],
					// // wh_packing_type:[],
					wh_car_tms_type: [],
					// wh_no:[],
					statusList: WarehouseShipments_status
				},
				//查询，排序方式
				filterData: {
					orderBy: null, //排序字段
					sortAsc: null, //desc降序，asc升序
					// "accountPeriod":"",
					// "billNo":""
					agentUser: "",
					// workNo: '',
					status: '',
					packingNo: '',
					keyword: "",
					commitDateArr: [],
					OutWhDateArr: [],
					whNo: '',
					wh_car_tms_type: '',
					sendNo: '',
					sendNoList: [],
					sendNoListStr: "",
					// putWhNo: '',
					// goodsSku: '',
					// hashCode: '',
					// goodsName: '',
					// declareNameCh: ''
				}
			};
		},
		// watch:{
		// 	'$route.query': function(newVal, oldVal) {
		//     console.log('watchKey');
		//     // if (newVal) {
		//     // console.log('watch openDateTime HyUpLoadfile.vue');
		// 		if(this.$route.query.workNo){
		// 			this.filterData.workNo = this.$route.query.workNo;
		// 			this.initData();
		// 		}

		//     // }
		//   }
		// },
		//创建时
		created() {
			// this.getPageData();
			// if (this.$route.query.workNo) {
			// 	this.filterData.workNo = this.$route.query.workNo;
			// 	// this.initData();
			// }
			if (this.$route.query.state) {
				this.filterData.status = this.$route.query.state;
				// this.initData();
			}
			if (this.$route.query && this.$route.query.agentUser) {
				this.filterData.agentUser = this.$route.query.agentUser;
				this.$nextTick(() => {
					this.$refs.SelAgentUser.initData(this.filterData.agentUser);
				});
			}
		},
		//编译挂载前
		mounted() {
			// this.getDicData();
			//数据字典
			getDicData(['wh_car_tms_type'],
				(data) => {
					this.selectOption.wh_car_tms_type = data['wh_car_tms_type'];
				});
			this.initData();
		},
		methods: {
			initData() {
				this.pagination.current_page = 1;
				// this.currentSel = {};
				this.multipleSelection = [];
				this.getPageData();
				// this.getDicData();
			},
			//打开更多筛选
			openMoreFilter() {
				this.drawerFilter = !this.drawerFilter;
			},
			i18nFormatter(value) {
				return i18nStatus(value, this.selectOption.statusList)
			},
			//查询更多筛选
			queryMoreFilter() {
				this.initData();
				// this.drawerFilter = false;
			},
			changeAgentUser(val) {
				this.filterData.agentUser = val;
				this.initData();
			},
			changWhNo(data) {
				console.log('changWhNo', data);
				this.filterData.whNo = data.code;
				// this.filterData.userId = data.userId;
				this.initData();
			},
			//选择行
			handleCurrentChange(row, event, column) {
				// this.$refs.multipleTable.toggleRowSelection(row, true); //点击选中
			},
			//多选
			handleSelectionChange(val) {
				console.log(val);
				this.multipleSelection = val;
			},

			//导出 excel
			exportExcel() {
				let columns = [{
						title: this.$t('i18nn_7f6c721b57ed0e7b'),
						key: 'sysOrderNo'
					},
					{
						title: this.$t('i18nn_a2df50399ce1bf10'),
						key: 'sendNo'
					},
					{
						title: this.$t('i18nn_c944a6686d996ab3'),
						key: 'whNo'
					},
					{
						title: 'FBA',
						key: 'isFbaName'
					},

					{
						title: 'FBA' + this.$t('i18nn_c944a6686d996ab3'),
						key: 'toWhNo'
					},
					// {
					// 	title: 'FBA.No',
					// 	key: 'fbaNo'
					// },
					// {
					// 	title: 'SKU',
					// 	key: 'goodsSku'
					// },

					{
						title: this.$t('i18nn_5165f3ee9377af83'),
						key: 'carTmsTypeName'
					},
					{
						title: this.$t('i18nn_31519f8b1453a53e'),
						key: 'sendOutTypeName'
					},

					{
						title: this.$t('i18nn_4429a015eeb36653'),
						key: 'palletCount'
					},
					{
						title: this.$t('i18nn_f88b93f4fd02974c'),
						key: 'palletLabel'
					},
					{
						title: this.$t('Storage.invoice_list.Expected_delivery_date'),
						key: 'carPlanDate'
					},
					{
						title: this.$t('i18nn_76e82afd9c2b6ca1'),
						key: 'commitDate'
					},
					{
						title: this.$t('i18nn_fb6105f4b294a9d0'),
						key: 'outWhDate'
					},
					{
						title: this.$t('i18nn_da7eef74bb8e0f6d'),
						key: 'waitOutWhDate'
					},
					{
						title: this.$t('i18nn_15b3627faddccb1d'),
						key: 'remark'
					},

					// SKU  发货件数 箱数 单箱数量 标签数 长宽高 箱唛 发货条码
					// goodsSku: item2.goodsSku,
					// sendAcount: item2.sendAcount,
					// sendCTN: item2.sendCTN,
					// ctnPCS: item2.ctnPCS,
					// orderLabel: item2.orderLabel,
					// goodsLWH: goodsLWH,
					// markNo: item2.markNo,
					// shipmentCode: item2.shipmentCode,
					{
						title: 'SKU',
						key: 'goodsSku'
					},
					{
						title: this.$t('i18nn_2ec0f364e8bbaa4c'),
						key: 'sendAcount'
					},
					{
						title: this.$t('i18nn_bdc361ba04506136'),
						key: 'sendCTN'
					},
					{
						title: this.$t('i18nn_4b2b4820019ef897'),
						key: 'ctnPCS'
					},
					{
						title: this.$t('i18nn_ac3f40a45db7c05a'),
						key: 'orderLabel'
					},
					{
						title: 'SKU' + this.$t('i18nn_98ea9b63e41e8543') + '(INC)',
						key: 'goodsLWH'
					},
					{
						title: 'SKU' + '重量' + '(LB)',
						key: 'goodsLBWeight'
					},
					{
						title: this.$t('i18nn_32df9cb3d1681477'),
						key: 'markNo'
					},
					{
						title: this.$t('i18nn_fa6ae0aebfee7efa'),
						key: 'shipmentCode'
					},
					// {
					// 	title: this.$t('i18nn_bdc361ba04506136'),
					// 	key: 'sendCTN'
					// },
					// {
					// 	title: this.$t('i18nn_3775920d44088b96'),
					// 	key: 'sendAcount'
					// },
					// {
					// 	title: this.$t('i18nn_85a10d3e5ea0e103'),
					// 	key: 'ctnPCS'
					// },
					// {
					// 	title: '单件重量(LB)',
					// 	key: 'goodsWeight'
					// },
					// {
					// 	title: '单件体积(m³)',
					// 	key: 'goodsVol'
					// },
					// {
					// 	title: '长*宽*高',
					// 	key: 'goodsLength',
					// 	types: 'custom',
					// 	formatter: function(row) {
					// 		if (row.goodsLength) {
					// 			return row.goodsLength + ' * ' + row.goodsWidth + ' * ' + row.goodsHeight;
					// 		} else {
					// 			return '';
					// 		}
					// 	}
					// }
				];
				let Data = [];

				// let data_dom = JQ('#ex_table').find(".el-table__body");
				// let obj_key_img = {};
				// // console.log(data_dom.find("tr"));
				// data_dom.find("tr").each((index, domEle)=>{
				// 	// console.log("index",index);
				// 	let imgObj = JQ(domEle).find("td .cell img");
				// 	// console.log("imgObj",imgObj);
				// 	if(imgObj){
				// 		obj_key_img[imgObj.attr("code")] = imgObj.attr("src");
				// 	}
				// })
				// // console.log("obj_key_img",obj_key_img);
				// Data.forEach(item=>{
				// 	item.barcodeimg = obj_key_img[item.whNo + '-' + item.place];
				// })

				// if (!this.filterData.wh_car_tms_type) {
				// 	this.$message.warning(this.$t('i18nn_5e3158c7817c7d9c'));
				// 	return;
				// }

				// if (!this.filterData.userId) {
				// 	this.$message.warning(this.$t('i18nn_5184f7a4033b4969'));
				// 	return;
				// }
				// if (!this.filterData.whNo) {
				// 	this.$message.warning(this.$t('i18nn_1118edcaeb4c3679'));
				// 	return;
				// }
				// if (!this.filterData.commitDateArr || this.filterData.commitDateArr.length < 2) {
				// 	this.$message.warning(this.$t('i18nn_ca037ca9e5a6af4c'));
				// 	return;
				// }
				this.expExcelData = Data;
				this.excelHead = columns;
				// if('1'==this.filterData.wh_car_tms_type){//自提
				// 	this.excelName = 'selfPickUp';
				// } else if('2'==this.filterData.wh_car_tms_type){
				// 	this.excelName = 'fbaTransshipment';
				// } else {
				// 	this.excelName = 'WhInShipmentsCommand';
				// }
				this.excelName = 'WarehouseShipments';

				this.excelOption = {
					height: 20,
					hasShowMerge: true,
					mergeLengthKey: 'sendRecordDtoList',
					mergeDataCol: ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N'],
					// mergeDataRow:[1,2],
				};
				this.excelOpenTime = new Date().getTime();

				// excelUtilsNew.exportExcel(columns, Data, 'WhDropShipping', { height: 20 });
			},

			//行操作更多
			rowMoreAction(command, row, index) {
				console.log('rowMoreAction', command);
				// event.stopPropagation();
				if ('openUploadFile' == command) {
					this.openUploadFile(row, index);
				} else if ('updataDestination' == command) {
					this.updataDestination(row, index);
				} else if ('openSendOutFile' == command) {
					this.openSendOutFile(row, index);
				} else if ('openPalletFile' == command) {
					this.openPalletFile(row, index);
				} else if ('downLoadBolFile' == command) {
					this.downLoadBolFile(row, index);
				} else if ('openQARecList' == command) {
					this.openQARecList(row, index);
				}


				// else if('toExpLgsTrackView'==command){
				// 	this.toExpLgsTrackView(row,index);
				// }
			},
			
			//附件更多
			fileMoreAction(command){
				console.log('fileMoreAction', command);
				if ('toBatchUplaodFile' == command) {
					this.toBatchUplaodFile();
				} else if ('toBatchDownloadOutFile' == command) {
					this.toBatchDownloadOutFile();
				} else if ('toBatchDownloadAllFile' == command) {
					this.toBatchDownloadAllFile();
				}
			},

			//跳转页面
			// toPageUrl(name){
			//   this.$router.push({'name':name});
			// },
			toCreate() {
				this.$router.push({
					name: "createOrder",
					query: {
						orderType: '30'
					}
				})
			},
			//打开新增编辑，弹窗
			// openDioalog(formParm) {
			// 	//   // console.log(formParm);
			// 	this.dialogFormVisible = true;
			// 	this.editId = formParm.id;
			// 	this.openTimeEditId = new Date().getTime();
			// 	//   let form = Object.assign({}, formParm);
			// 	//   console.log('form', form);
			// 	//   this.dialogFormMsg = msg;
			// 	//   // // 重置
			// 	//   this.resetForm('form');
			// 	//   if (null === formParm) {
			// 	//     //新增

			// 	//     // this.form.weightUnit = '1';
			// 	//     // this.form.volumeUnit = '1';
			// 	//     // this.form.whFeeType = '1';

			// 	//     this.dialogFormStatus = 0;
			// 	//     this.activeName = 'first';
			// 	//     // form.subUserId = null;
			// 	//     // form.userSubUserId = null;
			// 	//     // form.state = true;
			// 	//     //浅拷贝、对象属性的合并
			// 	//     this.form = form;
			// 	//   } else {
			// 	//     //修改
			// 	//     this.dialogFormStatus = 1;
			// 	//     // form.state = form.state === '0' ? true : false;
			// 	//     this.activeName = 'second';
			// 	//     //浅拷贝、对象属性的合并
			// 	//     this.form = form;
			// 	//   }
			// 	//   this.$forceUpdate();
			// 	//   // this.$alert('确定要确认通过该笔账单！', this.$t('tips.tipsTitle'), {
			// 	//   //       type: 'warning',
			// 	//   //       //confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
			// 	//   //     });
			// },
			// //打开编辑
			// openEdit(event, row, index) {
			// 	event.stopPropagation();
			// 	this.openDioalog(row);

			// 	// this.getPageDetData(row.id);
			// },


			// editSuccess() {
			// 	this.dialogFormVisible = false;
			// 	this.initData();
			// },

			//批量修改出车方式
			toBatchEditTmsTypeAction() {
				// editBatchTmsTypeRow:'',
				// editBatchTmsTypeOpenTime:{},
				let ids = [];
				let verify_msg = [];
				// if (!this.filterData.userId) {
				// 	verify_msg.push(this.$t('i18nn_18d7892498938435'));
				// }
				// if (!this.filterData.whNo) {
				// 	verify_msg.push(this.$t('c944a6686d996ab3'));
				// }
				ids = this.multipleSelection.map(item => {
					return item.id
				});
				if (ids.length <= 0) {
					verify_msg.push('勾选数据');
				}
				if (verify_msg.length > 0) {
					this.$message.warning(this.$t('2ad108ab2c560530') + verify_msg.join(','));
					return;
				}

				this.editBatchTmsTypeOpenTime = new Date().getTime()
			},

			//打开编辑
			openEdit(event, row, index) {
				event.stopPropagation();
				// this.openDioalog(row, this.$t('FormMsg.Edit'));
				// this.getPageDetData(row.id);
				this.$router.push({
					name: "createOrder",
					query: {
						orderType: '30',
						single: true,
						id: row.id
					}
				})
			},

			copyAction(event, row, index) {
				event.stopPropagation();
				// this.openDioalog(row, this.$t('FormMsg.Edit'));
				// this.getPageDetData(row.id);
				this.$router.push({
					name: "createOrder",
					query: {
						// cusUserId:row.userId,
						orderType: '30',
						single: true,
						id: row.id,
						copy: '1'
					}
				})
			},

			//查看出库附件
			openSendOutFile(row) {
				// event.stopPropagation();

				this.selFileListData = row;
				this.FileListTitle = this.$t('i18nn_67572c1c4fdfeab2');
				this.FileListTypeCode = "20";
				this.openTimeFileList = new Date().getTime();
			},
			//查看打托附件
			openPalletFile(row) {
				// event.stopPropagation();

				this.selFileListData = row;
				this.FileListTitle = this.$t('i18nn_0c0af12b8fb80f26');
				this.FileListTypeCode = "70";
				this.openTimeFileList = new Date().getTime();
			},

			//打开订单的输入框
			openOrderInputAction() {
				// this.filterData.sendNoListStr = "";
				this.dialogOrderInputVisible = true;
			},
			//确定订单的输入框
			sureOrderInputAction() {
				this.dialogOrderInputVisible = false;
				this.filterData.sendNo = '';
				let sendNoList = [];
				if (this.filterData.sendNoListStr) {
					sendNoList = this.filterData.sendNoListStr.split('\n').filter(function(s) {
						return s && s.trim();
					});
				}
				this.filterData.sendNoList = sendNoList;
				this.initData();
			},
			//清除搜索发货编号的值
			clearOrderList() {
				this.filterData.sendNoListStr = '';
				this.filterData.sendNoList = [];
				this.initData();
			},

			//批量上传附件
			toBatchUplaodFile() {
				//多条
				// let dataList = this.multipleSelection;
				if (this.multipleSelection.length < 1) {
					this.$message.warning(this.$t('Storage.DropShipping.sel_tips_1'));
					return;
				}
				// if (dataList.findIndex(item => '10' != item.status) > -1) {
				// 	this.$message.warning(this.$t('tips.long_WarehouseShipments_1'));
				// 	return;
				// }

				// let dataListParm = dataList.map(v => v.id);
				// parm = dataListParm;

				this.BatchFileUploadOpenTime = new Date().getTime();
			},

			//批量下载出库附件
			toBatchDownloadOutFile() {
				if (this.multipleSelection.length < 1) {
					this.$message.warning(this.$t('Storage.DropShipping.sel_tips_1'));
					return;
				}

				let fileList = [];
				this.multipleSelection.map(item => {
					item.attachments && item.attachments.map((item2, index2) => {
						if (20 == item2.type) {
							let fileName_suffix = '';
							if (0 == index2) {
								fileName_suffix = '';
							} else {
								fileName_suffix = '-' + index2;
							}
							fileList.push({
								fileName: item.sendNo + fileName_suffix,
								url: item2.url
							});
						}
					})
				});

				this.$confirm(`共 ${fileList.length} 个文件,是否继续?`, this.$t('tips.tipsTitle'), {
						type: 'warning'
					})
					.then(() => {
						fileZipAndDownloadReName(fileList, 'outWhFile');
					})
					.catch(() => {

					});
			},
			
			//批量下载全部附件
			toBatchDownloadAllFile(){
				if (this.multipleSelection.length < 1) {
					this.$message.warning(this.$t('Storage.DropShipping.sel_tips_1'));
					return;
				}
				
				let fileList = [];
				this.multipleSelection.map((item,index) => {
					item.attachments && item.attachments.map((item2, index2) => {
						// if (20 == item2.type) {
							// let fileName_suffix = '';
							// if (0 == index2) {
							// 	fileName_suffix = '';
							// } else {
							// 	fileName_suffix = '-' + index2;
							// }
							let fileName_suffix = item2.fileName.split('.')[0];
							// let fileName_suffix = old_file_name_suffix[0];
							fileList.push({
								fileName: item.sendNo+"_"+(index2+1)+'_' + fileName_suffix,
								url: item2.url
							});
						// }
					})
				});
				
				this.$confirm(`共 ${fileList.length} 个文件,是否继续?`, this.$t('tips.tipsTitle'), {
						type: 'warning'
					})
					.then(() => {
						fileZipAndDownloadReName(fileList, 'ShipmentFile');
					})
					.catch(() => {
				
					});
			},

			//明细
			//请求分页数据
			// getPageDetData(sendId) {
			//   // let _this = this;
			//   this.loading_det = true;
			//   this.$http.put(this.$urlConfig.WhShipmentsListDetPageList, {
			//       // "sortAsc": this.filterData.sortAsc,
			//       // "orderBy": this.filterData.orderBy,
			//       "offset": 0, //当前页
			//       "limit": 100, //当前页显示数目

			//       sendId: sendId,

			//       // putWhNo: this.filterData.putWhNo ? this.filterData.putWhNo : null,
			//       // goodsSku: this.filterData.goodsSku ? this.filterData.goodsSku : null,
			//       // hashCode: this.filterData.hashCode ? this.filterData.hashCode : null,
			//       // goodsName: this.filterData.goodsName ? this.filterData.goodsName : null,
			//       // declareNameCh: this.filterData.declareNameCh ? this.filterData.declareNameCh : null,
			//     })
			//     .then(({ data }) => {

			//     // console.log(this.$store.getters.getUserInfo);
			//       console.log("分页，请求成功");
			//       console.log(data);
			//       
			//       this.loading_det = false;
			//       //表格显示数据
			//       this.form.sendRecordDtoList = data.rows;
			//       //当前数据总条数
			//       // this.pagination.total = parseInt(data.total);
			//       //当前页数
			//       // this.pagination.current_page = parseInt(data.current);
			//       //当前页条数
			//       // this.pagination.page_size = parseInt(data.size);


			//     })
			//     .catch((error) => {
			//       console.log(error);
			//       console.log("分页，请求失败");
			//       this.loading_det = false;
			//     });
			// },

			//删除
			delAction(event, row) {
				event.stopPropagation();
				this.$confirm(this.$t("FormMsg.confirm_Delete"), this.$t('tips.tipsTitle'), {
						// confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
						// cancelButtonText: this.$t('i18nn_4b3340f7872b411f'),
						type: 'warning'
					})
					.then(() => {
						// this.$message({
						//   type: 'success',
						//   message: '删除成功!'
						// });
						this.delDataAction(event, row);
					})
					.catch(() => {
						// this.$message({
						//   type: 'info',
						//   message: this.$t('i18nn_2e58cb9b52e2a214')
						// });
					});
			},
			//删除
			delDataAction(event, row) {
				event.stopPropagation();
				console.log('delDataAction', row);
				// let parm = [];

				//   if (!!row) {
				//     //单条
				//     parm = [row.id];
				//   } else {
				//     //多条
				// //     let dataList = this.multipleSelection;
				// //     if (dataList.length < 1) {
				// //       this.$message.warning(this.$t('i18nn_7b80e66b535a3732'));
				// //       return;
				// //     }

				// //     let dataListParm = dataList.map(v => v.id);
				// //     parm = dataListParm;
				//     // console.log('dataListParm', dataListParm);
				//     // let dataParm = {
				//     //   ids: dataListParm
				//     // };
				//   }

				this.postData(this.$urlConfig.WhShipmentsListDel + '/' + row.id, {}, 'delete', () => {});
			},

			//提交
			commitAction(event, row) {
				event.stopPropagation();
				let parm = [];
				if (row) {
					parm = [row.id];
				} else {
					//多条
					let dataList = this.multipleSelection;
					if (dataList.length < 1) {
						this.$message.warning(this.$t('Storage.DropShipping.sel_tips_1'));
						return;
					}
					if (dataList.findIndex(item => '0' != item.status) > -1) {
						this.$message.warning("只有全部为 草稿 状态数据才可操作");
						return;
					}
					let dataListParm = dataList.map(v => v.id);
					parm = dataListParm;
				}
				this.$confirm(this.$t('Storage.DropShipping.sure_submit_1') + parm.length + this.$t(
						'Storage.DropShipping.sure_submit_2'), this.$t('tips.tipsTitle'), {
						type: 'warning'
					})
					.then(() => {
						this.postData(this.$urlConfig.WhShipmentsOrderCommit, {
							ids: parm
						}, '', () => {
							this.$message.success(this.$t('tips.submitSuccess'));
						});
					})
					.catch(() => {});
			},

			//撤销发货
			commitCancelAction(event, row) {
				event.stopPropagation();

				let parm = [];

				if (row) {
					//     //单条
					parm = [row.id];
				} else {
					//多条
					let dataList = this.multipleSelection;
					if (dataList.length < 1) {
						this.$message.warning(this.$t('Storage.DropShipping.sel_tips_1'));
						return;
					}
					if (dataList.findIndex(item => '10' != item.status) > -1) {
						this.$message.warning(this.$t('tips.long_WarehouseShipments_1'));
						return;
					}

					let dataListParm = dataList.map(v => v.id);
					parm = dataListParm;
					// console.log('dataListParm', dataListParm);
					// let dataParm = {
					//   ids: dataListParm
					// };
				}

				this.$confirm(this.$t('Storage.DropShipping.sure_submit_1') + parm.length + this.$t(
						'Storage.DropShipping.sure_submit_2'), this.$t('tips.tipsTitle'), {
						// confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
						// cancelButtonText: this.$t('i18nn_4b3340f7872b411f'),
						type: 'warning'
					})
					.then(() => {
						// {"ids":[11111111111111]}
						this.postData(this.$urlConfig.WhShipmentsOrderReturn, {
							"ids": parm
						}, '', () => {
							this.$message.success(this.$t('tips.submitSuccess'));
							// this.$alert(this.$t('tips.submitSuccess'), this.$t('tips.tipsTitle'), {
							// 		type: 'success'
							// 	});
						});
					})
					.catch(() => {});
			},

			//发货指令
			shipmentsAction(event, row) {
				event.stopPropagation();
				this.$confirm(this.$t('Storage.invoice_list.submit_sure'), this.$t('tips.tipsTitle'), {
						// confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
						// cancelButtonText: this.$t('i18nn_4b3340f7872b411f'),
						type: 'warning'
					})
					.then(() => {
						// this.$message({
						//   type: 'success',
						//   message: '删除成功!'
						// });
						// this.delDataAction(event, row);
						this.postData(this.$urlConfig.WhShipmentsListCommand + '/' + row.id, {}, '', () => {
							// this.initData();
							//  this.$alert('this.$t('tips.submitSuccess')'tips.submitSuccess')', this.$t('tips.tipsTitle'), {
							//   type: 'success',
							//   confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
							// });
							this.$message.success(this.$t('tips.submitSuccess'));
						});
					})
					.catch(() => {
						// this.$message({
						//   type: 'info',
						//   message: this.$t('i18nn_2e58cb9b52e2a214')
						// });
					});
			},

			//售后
			openQARecList(row) {
				// event.stopPropagation();
				this.QAMoRecListRelationId = row.id;
				this.QAMoRecListRelationNo = row.sendNo;
				// this.QAMoRecListUserId = row.userId;
				this.QAMoRecListWhNo = row.whNo;
				this.QAMoRecListOpenTime = new Date().getTime();
			},
			QAMoRecListSuccess() {
				this.initData();
			},

			//下载提货单
			downloadBillLading(event, row) {
				event.stopPropagation();
			},

			//编辑-预计到货日期
			openOtherEdit(event, row) {
				event.stopPropagation();
				this.editOpenTime = new Date().getTime();
				this.editRow = row;
				// this.PalletComplateType = 1;
			},
			//完成，回调
			complateSuccess() {
				this.initData();
			},

			//提交信息
			postData(url, formData, type, callback) {
				// HttpTypelet _this = this;
				this.loading = true;
				this.loading_load = true;
				let HttpType = {};
				if ('delete' == type) {
					HttpType = this.$http.delete(url, formData);
				} else {
					HttpType = this.$http.put(url, formData);
				}
				HttpType.then(({
					data
				}) => {
					console.log(this.$t('i18nn_bc868e024b80d2e3'));
					console.log(data);

					this.loading = false;
					this.loading_load = false;
					if (200 == data.code) {
						// this.dialogFormVisible = false;
						this.getPageData();
						if ("delete" == type) {
							this.$message({
								type: 'success',
								message: this.$t('tips.successfullyDelete')
							});
						} else {
							this.$alert(this.$t('tips.submitSuccess'), this.$t('tips.tipsTitle'), {
								type: 'success'
							});
						}
						callback();
					} else {
						// if (!data.msg) {
						//   data.msg = this.$t('tips.submitError');
						// }
						this.$alert(data.msg ? data.msg : this.$t("tips.submitError"), this.$t('tips.tipsTitle'), {
							type: 'warning'
							//confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
						});
					}
				}).catch(error => {
					console.log(error);
					console.log(this.$t('tips.submitError'));
					this.loading = false;
					this.loading_load = false;
					this.$alert(this.$t('tips.submitRequestError'), this.$t('tips.tipsTitle'), {
						type: 'warning'
						//confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
					});
				});
			},

			handleClick(tab, event) {
				console.log(tab, event);
				// this.form.sendRecordDtoList = [];
			},

			//更新目的地
			updataDestination(row) {
				// event.stopPropagation();
				this.TransportUpdataAdrOpenTime = new Date().getTime();
				this.TransportUpdataAdrRow = row;

			},

			successSubmitDestData(fbaAddrCode) {
				let formData = {
					"id": this.TransportUpdataAdrRow.id,
					"toWhNo": fbaAddrCode
				};
				console.log('formData', formData);
				this.postData(this.$urlConfig.WhShipmentUpdataAddress, formData, '', () => {
					this.$nextTick(() => {
						this.$refs.TransportUpdataDestination.close();
					})
				});
			},

			//地址
			showSelfAddress(event, row) {
				event.stopPropagation();
				this.updataDestination(row);
			},


			handleSelectStatus(key, keyPath) {
				// console.log(key, keyPath);
				this.filterData.status = key;
				this.initData();
			},

			//格式换待导出去数据
			formatterData(arr) {
				let newArr = [];
				arr.forEach(item => {
					if (item.sendRecordDtoList && item.sendRecordDtoList.length > 0) {
						// SKU  发货件数 箱数 单箱数量 标签数 长宽高 箱唛 发货条码
						item.sendRecordDtoList.forEach(item2 => {
							let goodsLWH = '';
							if (item2.mySkuDto) {
								goodsLWH = item2.mySkuDto.goodsINCLength + ' * \n' + item2.mySkuDto.goodsINCWidth + ' * \n' +
									item2.mySkuDto.goodsINCHeight;
							}
							let goodsLBWeight = '';
							if (item2.mySkuDto) {
								goodsLBWeight = item2.mySkuDto.goodsLBWeight;
							}
							newArr.push(Object.assign({}, item, {

								goodsSku: item2.goodsSku,
								sendAcount: item2.sendAcount,
								sendCTN: item2.sendCTN,
								ctnPCS: item2.ctnPCS,
								orderLabel: item2.orderLabel,
								goodsLWH: goodsLWH,
								goodsLBWeight: goodsLBWeight,
								markNo: item2.markNo,
								shipmentCode: item2.shipmentCode,
								// mergeLength: item.sendRecordDtoList.length?item.sendRecordDtoList.length:1;
							}));
						})
					} else {
						newArr.push(item);
					}
				});
				console.log('newArr', newArr);
				return newArr;
			},

			//排序改变
			sortChange({
				column,
				prop,
				order
			}) {
				console.log('sortChange', {
					column,
					prop,
					order
				});
				let sortData = FormatTableSort({
					column,
					prop,
					order
				});
				this.filterData.orderBy = sortData.orderBy;
				this.filterData.sortAsc = sortData.sortAsc;
				this.getPageData();
			},

			//分页的筛选项数据
			pageFilterData() {

				let commitDateArr = this.filterData.commitDateArr;
				let startCommitDate = '';
				let endCommitDate = '';
				if (commitDateArr && commitDateArr.length == 2) {
					startCommitDate = commitDateArr[0];
					endCommitDate = commitDateArr[1];
				}

				let OutWhDateArr = this.filterData.OutWhDateArr;
				let startOutWhDate = '';
				let endOutWhDate = '';
				if (OutWhDateArr && OutWhDateArr.length == 2) {
					startOutWhDate = OutWhDateArr[0];
					endOutWhDate = OutWhDateArr[1];
				}

				let sendNoList = [];

				if (this.filterData.sendNoListStr) {
					sendNoList = this.filterData.sendNoList;
				} else if (this.filterData.sendNo) {
					sendNoList = [this.filterData.sendNo];
				}

				return {

					"sortAsc": this.filterData.sortAsc,
					"orderBy": this.filterData.orderBy,
					// workNo: this.filterData.workNo ? this.filterData.workNo : null,
					proxyUserId: this.filterData.agentUser ? this.filterData.agentUser : null,
					status: this.filterData.status ? this.filterData.status : null,
					packingNo: this.filterData.packingNo ? this.filterData.packingNo : null,
					keyword: this.filterData.keyword ? this.filterData.keyword : null,
					// // putWhNo: this.filterData.putWhNo ? this.filterData.putWhNo : null,
					whNo: this.filterData.whNo ? this.filterData.whNo : null,

					startCommitDate: startCommitDate ? startCommitDate : null,
					endCommitDate: endCommitDate ? endCommitDate : null,

					startOutWhDate: startOutWhDate ? startOutWhDate : null,
					endOutWhDate: endOutWhDate ? endOutWhDate : null,

					sendNoList: sendNoList.length > 0 ? sendNoList : null,

					carTmsType: this.filterData.wh_car_tms_type ? this.filterData.wh_car_tms_type : null,
					// hashCode: this.filterData.hashCode ? this.filterData.hashCode : null,
					// goodsName: this.filterData.goodsName ? this.filterData.goodsName : null,
					// declareNameCh: this.filterData.declareNameCh ? this.filterData.declareNameCh : null,
				};
			},
			//请求分页数据
			getPageData() {

				let filterData = Object.assign({
						offset: (this.pagination.current_page - 1) * this.pagination.page_size, //当前页
						limit: this.pagination.page_size //当前页显示数目
					},
					this.pageFilterData()
				);

				// let _this = this;
				this.loading_load = true;
				this.$http
					.put(this.$urlConfig.WhShipmentsListPageList, filterData)
					.then(({
						data
					}) => {
						// console.log(this.$store.getters.getUserInfo);
						console.log('分页，请求成功');
						console.log(data);

						this.loading_load = false;
						//表格显示数据
						this.tableData = data.rows;
						//当前数据总条数
						this.pagination.total = parseInt(data.total);
						//当前页数
						// this.pagination.current_page = parseInt(data.current);
						//当前页条数
						// this.pagination.page_size = parseInt(data.size);
					})
					.catch(error => {
						console.log(error);
						console.log('分页，请求失败');
						this.loading_load = false;
					});
			},

			//新增
			// addLe2() {
			//   console.log('this.form', this.form);
			//   this.form.sendRecordDtoList.push({
			//     goodsSku: '', //this.$t('i18nn_31f887c1ada79a99'),
			//     sendAcount: null, //this.$t('i18nn_b9d4d3f6183bf3c1'),
			//     toWhNo: '', //this.$t('i18nn_0f859b9b45732564'),
			//     fbaNo:'',//fba编号
			//     mySkuDto: {
			//       // cusCode: '', //this.$t('i18nn_64fed2851f79d875'),
			//       goodsName: '', //this.$t('i18nn_b23004db5349dfd2'),
			//       goodsNameEn: '', //this.$t('i18nn_05141ac0139eaf01'),
			//       goodsImg: '', //this.$t('i18nn_877c5a0e44a0eb07'),
			//       // defCode: '', //this.$t('i18nn_791e36963e37617a'),
			//       // hashCode: '', //this.$t('i18nn_198738759379a3c0'),
			//       // declareNameCh: '', //this.$t('i18nn_73d237ea940222ce'),
			//       // declareNameEn: '', //this.$t('i18nn_9894786aad05f849'),
			//       // declarePrice: '', //this.$t('i18nn_fe28507421c4c3b3'),
			//       goodsWeight: '', //this.$t('i18nn_96f1a4b17e75892d'),
			//       sizeUnit: '', //this.$t('i18nn_38bc71c0dc55904b'),
			//       // weightUnit: '', //this.$t('i18nn_8e01866868930d09'),
			//       goodsLength: '', //this.$t('i18nn_2cba96917484569a'),
			//       goodsWidth: '', //this.$t('i18nn_9e24e0e4745f4948'),
			//       goodsHeight: '', //this.$t('i18nn_c5fa0857bc0df2d6'),
			//       safeStock: '', //this.$t('i18nn_004617dc47191cb8'),
			//       // sendAcount: null //this.$t('i18nn_b9d4d3f6183bf3c1'),
			//       // "id":"",//"数据id"
			//       remark: ''
			//     }
			//   });
			//   this.$forceUpdate();
			// },
			// //删除
			// delLe2(index) {
			//   this.form.sendRecordDtoList.splice(index, 1);
			// },
			//明晰
			showDetList($event, row) {
				$event.stopPropagation();
				this.dialogShowDet = true;
				this.sendId = row.id;
			},

			//打开附件上传
			openUploadFile(row) {
				// event.stopPropagation();
				// this.isShowFileUpload = true;
				this.fileRelationId = row.id;
				this.FileUploadOpenTime = new Date().getTime();
			},
			//附件上传成功回调
			FileUploadSuccess(data) {
				this.initData();
				// event.stopPropagation();
				// this.isShowFileUpload = true;
				// this.fileRelationId = row.id;
				// this.FileUploadOpenTime = new Date().getTime();
			},

			//下载BOL
			downLoadBolFile(row) {
				// event.stopPropagation();
				this.loading_load = true;
				downloadPdfBuffer(this.$urlConfig.WhShipmentDownloadBol + "/" + row.id,
					`BOL_${row.sendNo}_${new Date().getTime()}`);
				this.loading_load = false;
			},

			//操作
			// sureBillAction(row,msg,state) {
			//   console.log(this.$t('i18nn_4f5bb4ff8b3d804b'), row);
			//   console.log(row);
			//   this.$confirm(this.$t('i18nn_e8ec92802315a287')+msg+'通过该笔账单?', this.$t('tips.tipsTitle'), {
			//     //confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
			//     //cancelButtonText: this.$t('i18nn_4b3340f7872b411f'),
			//     type: 'warning'
			//   }).then(() => {
			//     //确认
			//     let dataParm = {
			//       ids:[row.id],
			//       state:state,
			//     }
			//     this.updatePageData(dataParm, msg);
			//   }).catch(() => {

			//   });

			// },
			//更新特定数据,
			// updatePageData(dataParm, msg) {
			//   // let _this = this;
			//   this.loading_load = true;
			//   //浅拷贝、对象属性的合并
			//   let dataParmAss = Object.assign({}, dataParm);
			//   // dataParmAss.isDefault = 1;
			//   this.$http.post(this.$urlConfig.PartnerMatchingFeeModifyPage, dataParmAss)
			//     .then(({ data }) => {
			//       console.log(msg + "特定数据，请求成功");
			//       console.log(data);
			//       if (200 == data.code) {
			//         this.$message.success(msg + '成功！');
			//         this.getPageData();
			//       } else {
			//         this.$message.warning(data.msg ? data.msg : msg + '失败,请重试');
			//       }
			//     })
			//     .catch((error) => {
			//       console.log(error);
			//       console.log("更新特定数据，请求失败");
			//       this.loading_load = false;
			//       this.$message.warning('' + msg + '失败,请重试！');
			//     });
			// },
			//查询数据字典
			// getDicData() {
			// 	// let _this = this;
			// 	// console.log(keyword);

			// 	// this.loading_load = true;
			// 	this.$http
			// 		.put(this.$urlConfig.HyDicQueryList, ['wh_car_tms_type'])
			// 		.then(({
			// 			data
			// 		}) => {
			// 			console.log('查询数据字典，请求成功');
			// 			console.log(data);
			// 			if (200 == data.code && data.data) {
			// 				// this.selectOption.wh_weight_unit = data.data['wh_weight_unit'];
			// 				// this.selectOption.wh_vol_unit = data.data['wh_vol_unit'];
			// 				// this.selectOption.wh_goods_fee_type = data.data['wh_goods_fee_type'];
			// 				// this.selectOption.wh_size_unit = data.data['wh_size_unit'];
			// 				this.selectOption.wh_car_tms_type = data.data['wh_car_tms_type'];
			// 				// this.selectOption.wh_no = data.data['wh_no'];
			// 			} else {
			// 				if (!data.msg) {
			// 					data.msg = this.$t("tips.submitError");
			// 				}
			// 				this.$message.warning(data.msg);
			// 			}
			// 		})
			// 		.catch(error => {
			// 			console.log(error);
			// 			console.log('查询数据字典接口，请求失败');
			// 			this.$message.error(this.$t("tips.submitRequestError"));
			// 		});
			// }
		}
	};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
	// /deep/ .el-input-number,
	// .el-select {

	// 	// width: 100px;
	// 	.el-input__inner {
	// 		text-align: left;
	// 	}
	// }

	// .form_msg {
	// 	color: #e6a23c;
	// }
</style>