<template>
	<div>
		<!-- <el-dialog :title="$t('c09a4ec2fe473b0a')" append-to-body :close-on-click-modal="false" :visible.sync="dialogFile" width="800px"> -->
		<el-drawer :wrapperClosable="false" :title="'认领'" append-to-body :visible.sync="dialogFile" :direction="'rtl'"
			size="800px">

			<div>
				<div style="">
					<el-form ref="modifyForm" size="small" :rules="modifyFormRules" :model="modifyForm" label-width="100px"
						v-loading="loading" :element-loading-text="$t('i18nn_1b4525c800280581')">

						<el-form-item :label="'仓库'" prop="">
							<!-- <whNoSelect ref="whNoSelect" @changeData="changWhNo">
							</whNoSelect> -->
							{{modifyForm.whNo}}
						</el-form-item>

						<el-form-item :label="'到仓时间'" prop="">
							<!-- <el-date-picker v-model="modifyForm.deliveryTime" type="datetime" value-format="yyyy-MM-dd HH:mm:ss" default-time="00:00:00"
								:placeholder="$t('FormMsg.Select_date_time')" style="width:200px;"></el-date-picker> -->

							{{modifyForm.deliveryTime}}
						</el-form-item>

						<el-form-item :label="'附件'" prop="">
							<div>
								<!-- <el-button type="primary" icon="el-icon-plus" size=""
									@click="openAddFile()">{{$t('c0246c55b9cac963')}}</el-button> -->
								<ul>
									<li v-for="(item,index) in fileList" :key="index">
										<el-image style="width: 50px; height: 50px;vertical-align: middle;" :z-index="9999" :fit="'contain'"
											:src="item.url" :preview-src-list="fileList.map(itemPre=> { return itemPre.url})">
											<div slot="error" class="image-slot">
												<i class="el-icon-document"></i>
											</div>
										</el-image>
										<a :href="item.url" :title="item.url" target="_blank">{{ item.fileName }}</a>
										<!-- <el-button @click="delFile(index)" type="text" size="mini" icon="el-icon-delete"
											style="padding: 0 10px;">{{$t('e33c9b93c36fd250')}}</el-button> -->
									</li>
								</ul>
							</div>
						</el-form-item>

						<el-form-item :label="'运单号'" prop="">
							<!-- <el-input size="" v-if="modifyForm.goodsParamsVo" v-model="modifyForm.goodsParamsVo.truckingNo"
								:placeholder="$t('5a9aefbc03c778f7')"></el-input> -->

							<span v-if="modifyForm.goodsParamsVo">
								{{modifyForm.goodsParamsVo.truckingNo}}
							</span>
						</el-form-item>

						<!-- <el-form-item :label="'客户'" prop="" required> -->
						<!-- <cusSelFuzzy ref="cusSelFuzzy" @changeData="changCus"></cusSelFuzzy> -->
						<!-- <el-input type="textarea" size="" :rows="3" v-model="modifyForm.remark" :maxlength="1000" show-word-limit
							:placeholder="$t('5a9aefbc03c778f7')"></el-input> -->
						<!-- </el-form-item> -->


						<el-form-item :label="'SKU'" prop="" required>
							<el-button type="primary" size="small" icon="el-icon-plus" @click="addSku()">新增</el-button>
							<div v-if="modifyForm.goodsParamsVo && modifyForm.goodsParamsVo.skuInfoArr">
								<el-table :data="modifyForm.goodsParamsVo.skuInfoArr" stripe :border="true"
									:max-height="$store.state.tableMaxHeight4" style="" size="small">


									<el-table-column prop="goodsSku" :label="'SKU'">
										<template slot-scope="scope">
											<el-input size="" v-model="scope.row.goodsSku" size="small"
												:placeholder="$t('5a9aefbc03c778f7')"></el-input>
										</template>
									</el-table-column>

									<el-table-column prop="qt" :label="'数量'">
										<template slot-scope="scope">
											<el-input-number v-model="scope.row.qt" size="small" style="width:200px;"></el-input-number>
										</template>
									</el-table-column>

									<el-table-column :label="$t('93f5ca01b006206c')" width="120px">
										<template slot-scope="scope">
											<div>
												<div style="margin-bottom: 5px;">
													<el-button @click="delSku($event, scope.row)" type="danger" size="mini" icon="el-icon-minus">
														{{ $t('e33c9b93c36fd250') }}
													</el-button>
												</div>
											</div>
										</template>
									</el-table-column>
								</el-table>
							</div>
							<!-- </el-card> -->
						</el-form-item>

						<el-form-item :label="$t('15b3627faddccb1d')" prop="">
							<el-input type="textarea" size="" :rows="3" v-model="modifyForm.remark" :maxlength="1000" show-word-limit
								:placeholder="$t('5a9aefbc03c778f7')"></el-input>
						</el-form-item>


					</el-form>
				</div>
			</div>

			<div class="drawer-footer">
				<el-button type="primary" plain @click="dialogFile = false">{{$t('FormMsg.Close')}}</el-button>
				<!-- <el-button type="warning" @click="SubmitForm('modifyForm','1')">仅保存</el-button> -->
				<el-button type="success" @click="SubmitForm('modifyForm','2')">保存并认领</el-button>
			</div>
		</el-drawer>

		<!-- 选择的SKU -->
		<dialogWSku :openTime="skuInvOpenTime" @sure="sureSel"></dialogWSku>

		<!-- 附件 -->
		<!-- <dialogFileUpload ref="dialogFileUpload" :folder="'UnclaimedGoods/'"
			:openTime="FileUploadOpenTime" @success="FileUploadSuccess"></dialogFileUpload> -->
	</div>
</template>
<script>
	import {
		getDicData
	} from '@/axios/common.js';

	import dialogWSku from '@/components/StorageCenter/WSkuProduct/dialogWSku.vue';

	// import dialogFileUpload from '@/components/StorageCenter/components/dialogFileUpload.vue';

	// import cusSelFuzzy from '@/components/StorageCenter/components/cusSelFuzzy.vue';
	// import whNoAllSelect from '@/components/WarehouseCenter2/components/whNoAllSelect.vue';

	// import CabPuCarrierSelFuzzyAll from '@/components/WarehouseCenter2/components/CabPuCarrierSelFuzzyAll.vue';

	// import longUsAddressParse from '@/components/WarehouseCenter2/components/longUsAddressParse.vue';

	// import CabPuLineSelFuzzyAll from '@/components/WarehouseCenter2/components/CabPuLineSelFuzzyAll.vue';

	import {
		clearObjectVal
	} from '@/utils/common.js';

	// import whNoSelect from '@/components/StorageCenter/components/whNoSelect.vue';
	export default {

		props: {
			openTime: {},
			row: {},
			// userId:{}
		},
		components: {
			// dialogFileUpload,
			// cusSelFuzzy,
			dialogWSku,
			// whNoAllSelect,
			// CabPuCarrierSelFuzzyAll,
			// longUsAddressParse,
			// CabPuLineSelFuzzyAll
			// whNoSelect
		},
		data() {
			return {
				dialogFile: false,
				loading: false,

				// addrParseOpenTime:'',
				//附件
				// fileWatchKey: '1',
				fileList: [],
				// FileUploadOpenTime: '',
				// fbaAddrCode: "", //FBA仓库地址编号
				// fbaAddrCode2: "",
				// whNoList: [], //仓库列表

				skuInvOpenTime: '',
				// skuUserId:'',

				//
				modifyForm: {
					"whNo": "",
					"deliveryTime": "",
					"userId": "",
					"goodsParamsVo": {
						"truckingNo": "",
						"skuInfoArr": [],
						// "skuInfoArr": [{
						// 	"goodsSku": "",
						// 	"qt": "1"
						// }]
					},
					"attachments": [],
				},
				modifyFormRules: {
					// skuPrefix: [{
					// 	required: true,
					// 	message: this.$t('FormMsg.Please_Enter'),
					// 	trigger: 'blur'
					// }],
				},
				selectOption: {
					// isUse:[
					// 	{
					// 		code:"0",
					// 		codeText:this.$t('i18nn_ab5902ee5c645e01'),
					// 	},{
					// 		code:"1",
					// 		codeText:this.$t('i18nn_e0199d6ecc3ee81a'),
					// 	},
					// ],
					// biz_wh_lgskey_code: [],
					// wh_place_type: []
				}

			};
		},
		watch: {
			openTime: function(newVal, oldVal) {
				console.log('openTime');
				this.dialogFile = true;
				this.initData();
			}
		},
		//创建时
		created() {
			// this.getPageData();
			//数据字典
			// getDicData(['biz_wh_lgskey_code'],
			// 	(data) => {
			// 		this.selectOption.biz_wh_lgskey_code = data['biz_wh_lgskey_code'];
			// 	});
		},
		//编译挂载前
		mounted() {
			// this.initData();
		},
		methods: {
			initData() {
				this.resetForm('modifyForm');
				// this.$nextTick(() => {
				// 	this.$refs.whNoSelect.init("");
				// });
				this.fileList = [];
				// this.fbaAddrCode ="";
				// this.fbaAddrCode2 ="";
				// this.$nextTick(()=>{
				// 	this.$refs.whNoAllSelect.init("");
				// 	// this.$refs.whNoAllSelect2.init("");
				// })

				//初始化上传控件
				if (this.row && this.row.id) {
					// this.modifyForm = Object.assign({}, this.row);
					this.queryDet(this.row.id);
				}



			},

			changWhNo(data) {
				console.log('changWhNo', data);
				this.modifyForm.whNo = data.code;
			},

			// changCus(data) {
			// 	console.log('changCus', data);
			// 	// this.filterData.cusNo = data.cusNo;
			// 	this.modifyForm.userId = data.userId;
			// 	// this.initData();
			// },

			//添加附件
			// openAddFile(index) {
			// 	// this.FileUploadOpenIndex = index;
			// 	this.FileUploadOpenTime = new Date().getTime();
			// },
			//删除附件
			// delFile(index) {
			// 	this.fileList.splice(index, 1);
			// 	this.$forceUpdate();
			// },
			// FileUploadSuccess(data) {
			// 	console.log('FileUploadSuccess', data);
			// 	// let item = this.complateDateForm.attachments[this.FileUploadOpenIndex];
			// 	let fileList = data.map(item => {
			// 		return {
			// 			// fileName: item.fileName, //this.$t('i18nn_89221ad15d2ec113'),
			// 			// url: item.filePath ,//this.$t('i18nn_8758fd50c87d6c9c')
			// 			type: '65',
			// 			fileName: item.fileName,
			// 			url: item.filePath
			// 		}
			// 	});
			// 	if (!this.fileList) {
			// 		this.fileList = [];
			// 	}
			// 	this.fileList = this.fileList.concat(fileList);
			// 	this.$forceUpdate();
			// 	// item.attachments = item.attachments.concat(fileList);
			// 	// this.$set(this.complateDateForm.attachments, this.FileUploadOpenIndex, item);
			// },

			// addSku() {
			// 	this.modifyForm.goodsParamsVo.skuInfoArr.push({
			// 		"goodsSku": "",
			// 		"qt": "1"
			// 	});
			// 	this.$forceUpdate()
			// },
			addSku() {
				// if(!this.modifyForm.userId){
				// 	this.$message.warning("请选择客户");
				// 	return;
				// }
				// this.skuUserId = this.modifyForm.userId;
				this.skuInvOpenTime = new Date().getTime();
			},
			sureSel(val) {
				val.forEach(item => {
					// let hasSku = this.form.packRecordList.some(item2=> item.goodsSku == item2.goodsSku);
					//是否已经存在此SKU
					// if(!hasSku){//不存在
					if (this.modifyForm.goodsParamsVo && this.modifyForm.goodsParamsVo.skuInfoArr) {
						this.modifyForm.goodsParamsVo.skuInfoArr.push({
							goodsSku: item.goodsSku,
							"qt": "1"
						});
					} else {
						this.modifyForm.goodsParamsVo.skuInfoArr = [{
							goodsSku: item.goodsSku,
							"qt": "1"
						}]
					}

					// }
				})
				this.$forceUpdate();
			},

			delSku(index) {
				this.modifyForm.goodsParamsVo.skuInfoArr.splice(index, 1);
				this.$forceUpdate()
			},



			//查询
			queryDet(id) {
				this.loading = true;
				this.$http
					.get(this.$urlConfig.WhUnclaimedGoodsEditQuery + "/" + id, {})
					.then(({
						data
					}) => {
						this.loading = false;
						if (200 == data.code && data.data) {
							this.modifyForm = data.data;
							this.fileList = this.modifyForm.attachments;
							
							if (!this.modifyForm.goodsParamsVo || !this.modifyForm.goodsParamsVo.skuInfoArr) {
								this.modifyForm.goodsParamsVo.skuInfoArr=[];;
							}
							
							// this.$nextTick(()=>{
							// 	this.$refs.whNoSelect.init(this.modifyForm.whNo);
							// 	this.$refs.cusSelFuzzy.init(this.modifyForm.userId);
							// });
						} else {
							this.$alert(data.msg ? data.msg : '查询失败', this.$t('tips.tipsTitle'), {
								type: 'warning'
							});
						}
					}).catch(error => {
						console.log(error);
						// console.log(this.$t('tips.submitError'));
						this.loading = false;
						this.$alert('查询失败！', this.$t('tips.tipsTitle'), {
							type: 'warning'
						});
					});
			},

			//提交
			SubmitForm(formName, type) { //1--仅保存，2---保存并认领
				this.$refs[formName].validate(valid => {
					if (valid) {
						let formData = Object.assign({}, this.modifyForm);
						formData.attachments = this.fileList;
						// if (formData.id) {
						// if(2==type){
						this.postData(this.$urlConfig.WhUnclaimedGoodsEdit, formData, () => {
							// this.$message.success(this.$t('i18nn_994cb374e601fab3'));
							// this.dialogFile = false;
							// this.$emit('success');
							this.postData(this.$urlConfig.WhUnclaimedGoodsClaim, {
								ids: [formData.id]
							}, () => {
								this.$message.success(this.$t('i18nn_994cb374e601fab3'));
								this.dialogFile = false;
								this.$emit('success');
							});
						});
						// } else {
						// 	this.postData(this.$urlConfig.WhUnclaimedGoodsEdit, formData, () => {
						// 		this.$message.success(this.$t('i18nn_994cb374e601fab3'));
						// 		this.dialogFile = false;
						// 		this.$emit('success');
						// 	});
						// }

						// } else {
						// 	this.postData(this.$urlConfig.WhUnclaimedGoodsAdd, [formData], () => {
						// 		this.$message.success(this.$t('i18nn_4ab989bd6abd3ac7'));
						// 		this.dialogFile = false;
						// 		this.$emit('success');
						// 	});
						// }
					} else {
						console.log('error submit!!');
						this.$alert(this.$t('tips.checkSubmitData'), this.$t('tips.tipsTitle'), {
							type: 'warning'
						});
						return false;
					}
				});
			},

			//重置输入框
			resetForm(formName) {
				console.log(formName);
				// this.modifyForm = clearObjectVal(this.modifyForm);


				if (this.$refs[formName]) {
					this.$refs[formName].resetFields();
				} else {
					console.log('this.$refs[formName]', this.$refs[formName]);
				}

				this.modifyForm = {
					"whNo": "",
					"deliveryTime": "",
					"userId": "",
					"goodsParamsVo": {
						"truckingNo": "",
						"skuInfoArr": [],
						// "skuInfoArr": [{
						// 	"goodsSku": "",
						// 	"qt": "1"
						// }]
					},
					"attachments": [],
				};
				// this.modifyForm.deliveryTime = this.$Utils.fomatterDate_YYYYMMddhms(new Date());
				// this.modifyForm.deliveryTime = this.$Utils.fomatterDate_YYYYMMdd(new Date());

			},

			postData(url, formData, callback) {
				this.loading = true;
				this.$http
					.put(url, formData)
					.then(({
						data
					}) => {
						console.log(this.$t('i18nn_bc868e024b80d2e3'));
						console.log(data);

						this.loading = false;
						if (200 == data.code) {
							callback();
						} else {
							this.$alert(data.msg ? data.msg : this.$t('tips.submitError'), this.$t('tips.tipsTitle'), {
								type: 'warning'
							});
						}
					}).catch(error => {
						console.log(error);
						console.log(this.$t('tips.submitError'));
						this.loading = false;
						this.$alert(this.$t('tips.submitRequestError'), this.$t('tips.tipsTitle'), {
							type: 'warning'
						});
					});
			},

		}
	};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less"></style>