import { render, staticRenderFns } from "./transferOrderSingleAdd.vue?vue&type=template&id=3b8c0631&scoped=true&"
import script from "./transferOrderSingleAdd.vue?vue&type=script&lang=js&"
export * from "./transferOrderSingleAdd.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3b8c0631",
  null
  
)

export default component.exports