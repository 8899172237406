<template>
	<div class="">
		<div class="tableCon" v-loading="loading_load" :element-loading-text="$t('47df8be257c59dde')">
			<div class="tableConTable">
				<div class="tableConTop">
					<el-row>
						<el-col :span="5" class="tableConTopLeft">
							<h3>
								<span class="tct_tit">已认领</span>
								<el-tooltip effect="dark" :content="$t('i18nn_90b7f7d6846dfdce')" placement="top">
									<el-button type="warning" circle icon="el-icon-download" size="mini"
										@click="exportExcel()"></el-button>
								</el-tooltip>
							</h3>
						</el-col>
						<el-col :span="19" class="tableConTopRig">
							<!-- <el-button @click="openAdd($event)" type="primary" size="small" icon="el-icon-plus">新增</el-button>
							
							<el-button @click="delAction($event, null)" type="danger" size="small" icon="el-icon-minus"
								v-if="'10'==filterData.status">
								{{ $t('e33c9b93c36fd250') }}
							</el-button> -->

							<!-- <el-button @click="openClaimBatch($event)" type="success" size="small"
								icon="el-icon-attract"v-if="'10'==filterData.status">
								认领
							</el-button> -->

							<!-- <el-button @click="openDestory($event,null)" type="danger" size="small"
								icon="el-icon-delete"v-if="'10'==filterData.status">
								销毁
							</el-button> -->

							<!-- <el-button @click="openBatchAdd($event)" type="primary" size="small" icon="el-icon-plus">批量新增</el-button> -->

							<!-- <el-button type="success" icon="el-icon-plus" size="small" @click="openValAdd($event,null)">
								批量更新增值服务
							</el-button> -->

							<!-- <el-button type="warning" icon="el-icon-edit-outline" size="small" @click="ClacBillAction($event,null)">
								账单计算
							</el-button> -->

							<el-button type="success" circle icon="el-icon-refresh" size="small" @click="initData()"></el-button>
						</el-col>
					</el-row>
				</div>

				<div>
					<div class="filterCon">
						<!-- <div class="filterBarList">
							<el-menu :default-active="filterData.status" mode="horizontal" @select="handleSelectStatus">
								<el-menu-item :index="item.value" v-for="item in selectOption.statusList"
									:key="item.value">{{item.label}}</el-menu-item>
							</el-menu>
						</div> -->

						<ul class="filterConList">

							<!-- <li>
								<span>{{ $t('hytxs0000060') }}</span>
								<cusSelFuzzy ref="cusSelFuzzy" @changeData="changCus"></cusSelFuzzy>
							</li> -->


							<!-- <li>
								<span>提柜供应商</span>
								<CabPuCarrierSelFuzzyAll ref="CabPuCarrierSelFuzzyAll" @changeData="changCabPuCarrier">
								</CabPuCarrierSelFuzzyAll>
							</li> -->

							<li>
								<span>{{ $t('c944a6686d996ab3') }}</span>
								<whNoSelect @changeData="changWhNo"></whNoSelect>
							</li>
							
							<li>
								<span>到仓时间</span>
								<el-date-picker v-model="filterData.deliveryTimeArr" type="datetimerange" align="right" unlink-panels
									range-separator="-" :start-placeholder="$t('i18nn_7d2ffc27a09ff792')"
									:end-placeholder="$t('i18nn_46f7be1133b3e2c6')" value-format="yyyy-MM-dd HH:mm:ss" format="yyyy-MM-dd HH:mm:ss"
									size="small" @change="initData()" :picker-options="pickerOptions" style="width: 340px"></el-date-picker>
							</li>
							
							<!-- </ul> -->

							<!-- <ul class="filterConList"> -->
							<!-- <li>
								<span>柜号</span>
								<el-input type="text" v-model="filterData.containerNo" size="small" clearable
									@keyup.enter.native="initData()" maxlength="300" :placeholder="$t('hytxs0000001')"
									style="width: 220px;" />
								<el-tooltip class="" effect="dark" :content="'多柜号搜索'" placement="top">
									<el-button icon="el-icon-s-operation" size="small" type="success" plain
										@click="openOrderInputAction()"></el-button>
								</el-tooltip>
							</li> -->

							<!-- <li>
                    <span>{{$t('i18nn_cabc5dc617eef30e')}}</span>

                    <el-date-picker
                      v-model="filterData.statisticsCurrentDate"
                      value-format="yyyy-MM-dd"
                      type="date"
                      size="small"
                      :placeholder="$t('68efabd029c860a9')"
                      @change="initData()"
                    ></el-date-picker>
                  </li> -->
							<!-- <li>
                  	<span>{{ $t('hytxs0000039') }}</span>
                  	<el-input
                  		type="text"
                  		v-model="filterData.keywords"
                  		size="small"
                  		clearable
                  		@keyup.enter.native="initData()"
                  		maxlength="50"
                  		:placeholder="$t('hytxs0000038')"
                  		style="width: 180px;"
                  	/>
                  </li> -->

							<li>
								<span>状态</span>
								<el-select size="small" filterable clearable v-model="filterData.status"
									:placeholder="$t('2ad108ab2c560530')" @change="initData()"style="width: 100px;">
									<el-option v-for="item in selectOption.statusList" :key="item.value"
										:label="item.label" :value="item.value">
									</el-option>
								</el-select>
							</li>

							<!-- <li>
										<span>{{ $t('hytxs0000034') }}</span>
										<el-input
											type="text"
											v-model="filterData.pArea"
											size="small"
											clearable
											@keyup.enter.native="initData()"
											maxlength="50"
											:placeholder="$t('hytxs0000001')"
											style="width: 180px;"
										/>
									</li>
									<li>
										<span>{{ $t('hytxs0000035') }}</span>
										<el-input
											type="text"
											v-model="filterData.place"
											size="small"
											clearable
											@keyup.enter.native="initData()"
											maxlength="50"
											:placeholder="$t('hytxs0000001')"
											style="width: 180px;"
										/>
									</li> -->
							<!-- </ul> -->
							<!-- <ul class="filterConList"> -->
							<!-- <li>
								<span>是否空柜</span>
								<el-select size="small" filterable clearable v-model="filterData.isEmpty"
									:placeholder="$t('2ad108ab2c560530')" style="width: 100px;" @change="initData()">
									<el-option :label="'全部'" :value="null"></el-option>
									<el-option :label="'是'" :value="'1'"></el-option>
									<el-option :label="'否'" :value="'0'"></el-option>
								</el-select>
							</li> -->

							<li>
								<span>{{$t('i18nn_f353b1ebbdc25be4')}}</span>
								<el-input type="text" v-model="filterData.keyword" size="small" clearable
									@keyup.enter.native="initData()" maxlength="50" :placeholder="$t('i18nn_3ee4c9b76289e93a')"
									style="width: 180px;" />
							</li>

							<li>
								<el-button icon="el-icon-search" size="small" type="primary"
									@click="initData()">{{ $t('1e7246dd6ccc5539') }}</el-button>
							</li>
						</ul>

						<!-- 多柜号搜索显示 -->
						<!-- <div class="" style="padding: 0 10px 10px 10px;"
							v-if="filterData.containerNoList && filterData.containerNoList.length > 0">

							<ul class="filterConList" style="text-align: left;">
								<el-button icon="el-icon-close" size="small" type="success" @click="clearOrderList()">
									<span>{{$t('i18nn_4c4e546aa3683ff5')}}</span>
									(<span>多柜号搜索</span>)
									(<span>共<span>{{filterData.containerNoList.length}}</span></span>)
								</el-button>
								<li v-for="(item, index) in filterData.containerNoList" :key="index">
									<el-tag size="small" effect="plain">{{ item }}</el-tag>
								</li>
							</ul>
						</div> -->

					</div>
				</div>

				<el-table id="ex_table" ref="multipleTable" :data="tableData" stripe :border="true"
					:height="$store.state.frameConHeightWh2" @row-click="handleCurrentChange"
					@selection-change="handleSelectionChange" @sort-change="sortChange" style="width: 100%" size="small"
					v-loading="loading">

					<!-- <el-table-column type="selection" fixed="left" align="center" width="50"
						label-class-name="nodra"></el-table-column> -->

					<el-table-column type="index" :index="$Utils.tableIndex(pagination)" fixed="left" width="50" align="center"
						:label="$t('7b1c2b1adc920d9c')"></el-table-column>
					<!-- <el-table-column prop="id" label="ID"></el-table-column> -->


					<!-- <el-table-column prop="userId" :label="'userId'">
					</el-table-column> -->
					<el-table-column prop="statusName" :label="'状态'">
						<template slot-scope="scope">
							<div>
								<el-tag type="" v-if="'0' == scope.row.status">
									{{ $Utils.i18nKeyText(scope.row,'statusName') }}
								</el-tag>
								<el-tag type="success" v-else-if="'10' == scope.row.status">
									{{ $Utils.i18nKeyText(scope.row,'statusName') }}
								</el-tag>
								<el-tag type="warning" v-else-if="'20' == scope.row.status">
									{{ $Utils.i18nKeyText(scope.row,'statusName') }}
								</el-tag>
								<el-tag type="danger" v-else-if="'30' == scope.row.status">
									{{ $Utils.i18nKeyText(scope.row,'statusName') }}
								</el-tag>
								<el-tag type="success" v-else-if="'50' == scope.row.status">
									{{ $Utils.i18nKeyText(scope.row,'statusName') }}
								</el-tag>
								<el-tag type="warning" v-else-if="'70' == scope.row.status">
									{{ $Utils.i18nKeyText(scope.row,'statusName') }}
								</el-tag>
								<el-tag type="danger" v-else-if="'80' == scope.row.status">
									{{ $Utils.i18nKeyText(scope.row,'statusName') }}
								</el-tag>
								<el-tag type="" v-else-if="'90' == scope.row.status">
									{{ $Utils.i18nKeyText(scope.row,'statusName') }}
								</el-tag>
								<el-tag type="info" v-else>
									{{ $Utils.i18nKeyText(scope.row,'statusName') }}
								</el-tag>
							</div>

						</template>
					</el-table-column>
					<el-table-column prop="whNo" :label="'仓库'">
					</el-table-column>

					<!-- <el-table-column prop="cusName" :label="$t('hytxs0000060')" width="100"
						show-overflow-tooltip></el-table-column> -->

					<el-table-column prop="deliveryTime" :label="'到仓时间'" :sortable="'custom'" min-width="100">
					</el-table-column>
					<el-table-column prop="unclaimedDay" :label="'到仓天数'">
						<template slot-scope="scope">
							<div>
								<el-tag type="success" size="small" v-if="scope.row.unclaimedDay<3">
									{{scope.row.unclaimedDay}}
								</el-tag>
								<el-tag type="danger" size="small" v-else>
									{{scope.row.unclaimedDay}}
								</el-tag>
							</div>
						</template>
					</el-table-column>


					<el-table-column prop="truckingNo" :label="'运单号'">
						<template slot-scope="scope">
							<div v-if="scope.row.goodsParamsVo">
								{{scope.row.goodsParamsVo.truckingNo}}
							</div>
						</template>
					</el-table-column>
					<el-table-column prop="goodsSku" :label="'SKU'">
						<template slot-scope="scope">
							<div v-if="scope.row.goodsParamsVo" class="recDet">
								<div class="recItem" v-for="(item,index) in scope.row.goodsParamsVo.skuInfoArr">
									{{item.goodsSku}}&nbsp;*&nbsp;{{item.qt}}
								</div>
							</div>
						</template>
					</el-table-column>

					<el-table-column prop="relationNo" :label="'关联单号'">
					</el-table-column>

					<el-table-column prop="attachments" :label="$t('Storage.packing_list.attachment')">
						<template slot-scope="scope">
							<div>
								<div v-if="scope.row.attachments && scope.row.attachments.length>0">
									<el-image lazy style="width: auto; height: 40px" fit="contain" :src="scope.row.attachments[0].url"
										:preview-src-list="scope.row.attachments.map(itemPre=> { return itemPre.url})">
										<div slot="error" class="image-slot" style="font-size: 40px;"><i
												class="el-icon-picture-outline"></i></div>
									</el-image>
								</div>
								<div @click="openViewFile($event, scope.row)">
									<div v-if="scope.row.attachments">
										<el-link type="primary">
											...<span>{{$t('i18nn_73bbf51487ec69e9')}}</span><span>{{scope.row.attachments.length}}</span><span>{{$t('i18nn_7f18cb0ceb569eb0')}}</span>
										</el-link>
									</div>
								</div>
							</div>
							
						</template>
					</el-table-column>


					<el-table-column prop="remark" :label="$t('15b3627faddccb1d')">
						<template slot-scope="scope">
							<div>
								<el-popover placement="top" trigger="hover">
									<div class="pre-text">
										{{scope.row.remark}}
									</div>
									<span class="over_ellipsis" slot="reference">
										{{scope.row.remark}}
									</span>
								</el-popover>
							</div>
						</template>
					</el-table-column>

					<el-table-column prop="updateTime" :label="$t('i18nn_fdc34fd7121f9c48')" width="150"
						:sortable="'custom'"></el-table-column>
					<el-table-column prop="createTime" :label="$t('i18nn_726c51702f70c486')" width="150"
						:sortable="'custom'"></el-table-column>
					<!-- <el-table-column :label="$t('93f5ca01b006206c')" width="220px" fixed="right">
						<template slot-scope="scope">
							<div>
								<div style="margin-bottom: 5px;" v-if="'10'==scope.row.status">
									<el-button @click="openClaim($event, scope.row, scope.$index)" type="success" size="mini"
										icon="el-icon-attract">
										认领
									</el-button>
								</div>
							</div>
						</template>
					</el-table-column> -->
				</el-table>
			</div>
			<div class="tableConPagination"><hy-page-pagination :pagination="pagination"
					v-on:updatePageData="getPageData"></hy-page-pagination></div>
		</div>

		<!-- 柜号输入框 -->
		<!-- <el-dialog :title="'柜号输入'" append-to-body :close-on-click-modal="false"
			:visible.sync="dialogContainerNoInputVisible" width="1000px">
			<div style="">
				<h3>{{$t('i18nn_380912455c6e2f06')}}</h3>
				<el-input type="textarea" :autosize="{ minRows: 20 }" v-model="filterData.containerNoListStr" size="small"
					clearable show-word-limit maxlength="10000" :placeholder="'一行输入一个柜号'"
					style="width: 100%;vertical-align: middle; " />
			</div>
			<div slot="footer" class="dialog-footer">
				<el-button type="primary" plain @click="dialogContainerNoInputVisible = false">{{ $t('4e9fc68608c60999') }}
				</el-button>
				<el-button type="primary" @click="sureOrderInputAction()">{{$t('i18nn_b5511889be42a3da')}}</el-button>
			</div>
		</el-dialog> -->

		<!-- 新增 -->
		<!-- <UnclaimedGoodsAdd :openTime="EditOpenTime" :row="EditRow" @success="EditSuccess">
		</UnclaimedGoodsAdd> -->

		<!-- 认领-单个 -->
		<!-- <UnclaimedGoodsClaim :openTime="ClaimOpenTime" :row="EditRow" @success="EditSuccess">
		</UnclaimedGoodsClaim> -->

		<!-- 认领-批量 -->
		<!-- <UnclaimedGoodsClaimBatch :openTime="ClaimBatchOpenTime" :sels="multipleS" @success="EditSuccess">
		</UnclaimedGoodsClaimBatch> -->

		<!-- 销毁 -->
		<!-- <UnclaimedGoodsDestroy :openTime="DestroyOpenTime" :sels="multipleS" @success="EditSuccess">
		</UnclaimedGoodsDestroy> -->

		<!--  附件上传 -->
		<!-- <whFileUpload :openTime="FileUploadOpenTime" :relationId="fileRelationId" :userId="FileUploadUserId"
			@success="FileUploadSuccess">
		</whFileUpload> -->

		<!--  附件上传-DO确认 -->
		<!-- <whFileUploadByType :openTime="FileTypeUploadOpenTime" :relationId="FileTypeRelationId" :title="'DO确认'"
			:typeCode="'35'" :userId="FileTypeUploadUserId" @success="FileTypeUploadSuccess">
		</whFileUploadByType> -->

		<!-- 附件预览 -->
		<whFileView2 :openTime="FileViewOpenTime" :fileList="fileDataList"></whFileView2>

		<!-- excel 导出排序 -->
		<whExcelCustom :openTime="excelOpenTime" :excelHead="excelHead" :excelData="expExcelData" :pagination="pagination"
			:excelName="excelName" :excelOption="excelOption" :expHttpUrl="$urlConfig.WhUnclaimedGoodsPageList"
			:expHttpFilter="pageFilterData()"></whExcelCustom>

	</div>
</template>
<script>
	// import JQ from 'jquery'
	import {
		FormatTableSort
	} from '@/utils/utils.js';

	import {
		deepClone
	} from '@/utils/utils.js';

	import {
		getDicData
	} from '@/axios/common.js';

	import whFileView2 from '@/components/StorageCenter/components/whFileView2.vue';

	// import whFileUpload from '@/components/WarehouseCenter2/components/whFileUpload.vue';

	// import cusSelFuzzy from '@/components/WarehouseCenter2/components/cusSelFuzzy.vue';
	import whNoSelect from '@/components/StorageCenter/components/whNoSelect.vue';

	import whExcelCustom from '@/components/StorageCenter/components/whExcelCustom.vue';

	// import UnclaimedGoodsAdd from '@/components/WarehouseCenter2/ExchangeReturn/UnclaimedGoodsAdd.vue';
	// import UnclaimedGoodsClaim from '@/components/StorageCenter/ExchangeReturn/UnclaimedGoodsClaim.vue';
	// import UnclaimedGoodsClaimBatch from '@/components/StorageCenter/ExchangeReturn/UnclaimedGoodsClaimBatch.vue';
	// import UnclaimedGoodsDestroy from '@/components/WarehouseCenter2/ExchangeReturn/UnclaimedGoodsDestroy.vue';


	export default {
		components: {
			whFileView2,
			// whFileUpload,
			// whFileUploadByType,
			// cusSelFuzzy,
			whNoSelect,
			whExcelCustom,
			// CabPuCarrierSelFuzzyAll,
			// barcode,
			// UnclaimedGoodsAdd,
			// UnclaimedGoodsClaim,
			// UnclaimedGoodsClaimBatch,
			// UnclaimedGoodsDestroy,
			// whExcelCustom
		},
		// props: {
		// 	// mobile:"",
		// 	whNo: {
		// 		default: function() {
		// 			return '';
		// 		},
		// 		type: String
		// 	},
		// 	isSel: {
		// 		default: function() {
		// 			return false;
		// 		},
		// 		type: Boolean
		// 	}
		// },
		data() {
			return {
				//excel导出
				excelOpenTime: '',
				excelHead: [],
				expExcelData: [],
				excelName: '',
				excelOption: {},
				
				pickerOptions: this.$PickerDate.pickerOptions_1(),
				// userId: this.$store.getters.getUserInfo.id,
				//do 确认
				// FileTypeUploadOpenTime: "",
				// FileTypeRelationId: "",
				// FileTypeUploadUserId: "",

				// 附件上传
				// fileRelationId: '',
				// FileUploadOpenTime: '',
				// FileUploadUserId: "",

				//附件预览
				FileViewOpenTime: '',
				fileDataList: [],

				// EditOpenTime: "",
				EditRow: {},
				
				// ClaimOpenTime: "",
				// ClaimBatchOpenTime: "",
				// DestroyOpenTime: "",
				// editUserId: "",

				// ValAddOpentTime: "",
				// ValAddRows: {},
				// ValAddUserId:"",

				// EditOpenEmptyTime: "",
				// EditEmptyRow: "",

				// DoSureOpenTime: "",
				// DoSureRow: {},

				// openTimeLogDsDet: '',
				// LogDsDetRow: {},

				//柜号输入弹窗
				// dialogContainerNoInputVisible: false,
				//打开入库选择框
				// dialogSelVisible: false,
				// dialogVisible2:false,
				// loading_pdf: false,
				// PdfData:[],
				//选择
				currentSel: {},
				//表格数据
				//loading,表格数据
				loading: false,
				loading_load: false,
				//表格数据
				tableData: [],
				//缓存，原数据
				// tableDataCatch: [],

				//分页数据
				pagination: this.$Utils.defaultPagination(),
				//表格选择的数据
				multipleSelection: [],
				multipleS: [],
				//查询，排序方式
				//"orderBy": "auditTime,fCon,goodsCon", //排序字段
				selectOption: {
					// isSelfUserList: [{
					// 		value: '',
					// 		label: this.$t('16853bda54120bf1')
					// 	},
					// 	{
					// 		value: '1',
					// 		label: '本人'
					// 	},
					// ],
					statusList: [{
							value: '',
							label: this.$t('16853bda54120bf1')
						},
						// {
						// 	value: '10',
						// 	label: '待认领'
						// },
						{
							value: '20',
							label: '已认领'
						},
						{
							value: '80',
							label: '已销毁'
						},
					],
				},

				filterData: {
					orderBy: null, //排序字段
					sortAsc: null, //desc N降序，asc Y升序

					status: '',
					whNo: '',
					keywords: '',
					
					deliveryTimeArr:[],

					isSelf: null,

					isEmpty: '',
					containerNo: '',
					// userId: this.$store.getters.getUserInfo.id,
					puCompany: "",

				}
			};
		},

		activated() {

		},
		//创建时
		created() {
			this.initData();
		},
		//编译挂载前
		mounted() {},
		methods: {
			initData() {
				//跳转到第一页
				this.pagination.current_page = 1;
				this.currentSel = {};
				this.multipleSelection = [];
				this.initTable();
				this.getPageData();

			},
			initTable() {
				this.$nextTick(() => {
					this.$refs.multipleTable.doLayout();
				});
				this.$forceUpdate();
			},

			// handleSelectStatus(key, keyPath) {
			// 	// console.log(key, keyPath);
			// 	this.filterData.status = key;
			// 	// if('10'==this.filterData.status){
			// 	// 	this.filterData.isSelf = null;
			// 	// }
			// 	this.initData();
			// },

			// changCus(data) {
			// 	console.log('changCus', data);
			// 	// this.filterData.cusNo = data.cusNo;
			// 	this.filterData.userId = data.userId;
			// 	this.initData();
			// },
			changWhNo(data) {
				console.log('changWhNo', data);
				this.filterData.whNo = data.code;
				// this.filterData.userId = data.userId;
				this.initData();
			},

			// changCabPuCarrier(data) {
			// 	console.log('changCabPuCarrier', data);
			// 	// this.filterData.cusNo = data.cusNo;
			// 	this.filterData.puCompany = data.carrierCode;
			// 	this.initData();
			// },

			//导出 excel
			exportExcel() {
				let columns = [{
						title: '状态',
						key: 'statusName'
					},
					{
						title: '仓库',
						key: 'whNo'
					},
					// {
					// 	title: this.$t('hytxs0000060'),
					// 	key: 'cusName'
					// },
					{
						title: '到仓时间',
						key: 'deliveryTime'
					},
					{
						title: '到仓天数',
						key: 'unclaimedDay'
					},
					// {
					// 	title: '运单号',
					// 	key: 'truckingNo',
					// 	goodsParamsVo.truckingNo
					// },
					{
						title: '运单号',
						key: 'truckingNo',
						types: 'custom',
						formatter: function(row) {
							return row.goodsParamsVo.truckingNo;
						}
					},
					// {
					// 	title: 'SKU',
					// 	key: 'goodsParamsVo.skuInfoArr'
					// },
					{
						title: 'SKU',
						key: 'goodsSku',
						types: 'custom',
						formatter: function(row) {
							if (row.goodsParamsVo && row.goodsParamsVo.skuInfoArr && row.goodsParamsVo.skuInfoArr.length > 0) {
								return row.goodsParamsVo.skuInfoArr.map(item => item.goodsSku + ' * ' + item.qt).join(',');
							} else {
								return '';
							}
						}
					},
					{
						title: '关联单号',
						key: 'relationNo'
					},
					{
						title: this.$t('15b3627faddccb1d'),
						key: 'remark'
					},
					{
						title: this.$t('i18nn_fdc34fd7121f9c48'),
						key: 'updateTime'
					},
					{
						title: this.$t('i18nn_726c51702f70c486'),
						key: 'createTime'
					},
					// {
					// 	title: this.$t('i18nn_11e41b2ec3d872ed'),
					// 	key: 'goodsWeight',
					// 	types: 'custom',
					// 	formatter: function(row) {
					// 		return row.packRecord.goodsWeight;
					// 	}
					// },
					// {
					// 	title: this.$t('b6bf0a07fe26f74f'),
					// 	key: 'sizeUnitName',
					// 	types: 'custom',
					// 	formatter: function(row) {
					// 		return row.packRecord.sizeUnitName;
					// 	}
					// }
				];
				let Data = this.tableData;

				// if(!this.filterData.userId){
				// 	this.$message.warning(this.$t('i18nn_07e0f8f59293e78d'));
				// 	return;
				// }
				this.expExcelData = Data;
				this.excelHead = columns;
				this.excelName = 'UnclaimedGoodsListSelf';
				this.excelOption = {
					height: 20
				};
				this.excelOpenTime = new Date().getTime();

				// excelUtilsNew.exportExcel(columns, Data, 'WhDropShipping', { height: 20 });
			},

			//选择仓库
			// changeDataWhNo(data) {
			// 	console.log('changeDataWhNo', data);
			// 	this.form.whNo = data.code;
			// },

			//去日志
			// toLogsActionView(event, row) {
			// 	event.stopPropagation();
			// 	// this.$router.push({ name: 'WhDropShippingDet', query: { containerNo: row.containerNo } });
			// 	this.LogDsDetRow = row;
			// 	this.openTimeLogDsDet = new Date().getTime();

			// },
			//打开认领
			// openClaim(event, row) {
			// 	event.stopPropagation();
			// 	this.EditRow = row;
			// 	this.ClaimOpenTime = new Date().getTime();
			// },

			//打开认领-批量
			// openClaimBatch(event) {
			// 	event.stopPropagation();
			// 	let parm = [];
			// 	if (this.multipleSelection.length < 1) {
			// 		this.$message.warning(this.$t('i18nn_fc089dc894530f12'));
			// 		return;
			// 	}
			// 	parm = this.multipleSelection;
			// 	// }
			// 	this.multipleS = parm;
			// 	this.ClaimBatchOpenTime = new Date().getTime();
			// },

			//打开销毁
			// openDestory(event, row) {
			// 	event.stopPropagation();
			// 	let parm = [];
			// 	if (row) {
			// 		parm = [row];
			// 	} else {
			// 		//多条
			// 		// let dataList = this.multipleSelection;
			// 		if (this.multipleSelection.length < 1) {
			// 			this.$message.warning(this.$t('i18nn_fc089dc894530f12'));
			// 			return;
			// 		}
			// 		// if (dataList.findIndex(item=> '10'!=item.status)>-1) {
			// 		//   this.$message.warning("待认领状态才可以操作");
			// 		//   return;
			// 		// }

			// 		// let dataListParm = dataList.map(v => v.id);
			// 		parm = this.multipleSelection;
			// 	}
			// 	this.multipleS = parm;
			// 	this.DestroyOpenTime = new Date().getTime();
			// },

			//新增
			// openAdd(event, row) {
			// 	event.stopPropagation();

			// 	if (row && row.id) {
			// 		this.EditRow = row;
			// 		// this.editUserId = row.userId;
			// 	} else {
			// 		this.EditRow = null;
			// 		// this.editUserId = this.filterData.userId;
			// 		// if (!this.editUserId) {
			// 		// 	this.$message.warning('请先选择客户')
			// 		// 	return;
			// 		// }
			// 	}
			// 	this.EditOpenTime = new Date().getTime();
			// },

			// EditSuccess() {
			// 	this.initData();
			// },

			//打开附件查看
			openViewFile(event, row) {
				event.stopPropagation();
				this.fileDataList = row.attachments;
				this.FileViewOpenTime = new Date().getTime();
			},

			//打开附件上传
			// openUploadFile(event, row) {
			// 	event.stopPropagation();
			// 	// this.isShowFileUpload = true;
			// 	// this.FileUploadUserId = row.userId;
			// 	this.FileUploadUserId = this.$store.getters.getUserInfo.id;
			// 	this.fileRelationId = row.id;
			// 	this.FileUploadOpenTime = new Date().getTime();
			// },
			// //附件上传成功回调
			// FileUploadSuccess(data) {
			// 	this.initData();
			// },

			//选择行
			handleCurrentChange(row, event, column) {
				// this.$refs.multipleTable.toggleRowSelection(row, true); //点击选中
			},
			//多选
			handleSelectionChange(val) {
				console.log(val);
				this.multipleSelection = val;
			},

			//编辑
			// openEdit(event, row, index) {
			// 	event.stopPropagation();
			// 	console.log('openEdit', row);
			// 	this.openAdd(event, row);
			// },

			//删除
			// delAction(event, row) {

			// 	let parm = [];
			// 	if (row) {
			// 		parm = [row.id];
			// 	} else {
			// 		//多条
			// 		let dataList = this.multipleSelection;
			// 		if (dataList.length < 1) {
			// 			this.$message.warning(this.$t('i18nn_fc089dc894530f12'));
			// 			return;
			// 		}
			// 		// if (dataList.findIndex(item=> '10'!=item.status)>-1) {
			// 		//   this.$message.warning("待认领状态才可以操作");
			// 		//   return;
			// 		// }
			// 		let dataListParm = dataList.map(v => v.id);
			// 		parm = dataListParm;
			// 	}
			// 	// this.multipleS = parm;

			// 	this.$confirm(this.$t('i18nn_d96afd0c0de3686c') + '?', this.$t('daaaeb1b7b22b126'), {
			// 			type: 'warning'
			// 		})
			// 		.then(() => {
			// 			this.delDataAction(event, parm);
			// 		})
			// 		.catch(() => {

			// 		});
			// },
			//删除
			// delDataAction(event, parm) {
			// 	event.stopPropagation();
			// 	// console.log('delDataAction', parm);

			// 	this.postData(this.$urlConfig.WhUnclaimedGoodsDel, parm, () => {
			// 		this.initData();
			// 		this.$message.success(this.$t('9f30371831a98237'));
			// 	}, '');
			// },

			//排序改变
			sortChange({
				column,
				prop,
				order
			}) {
				console.log('sortChange', {
					column,
					prop,
					order
				});
				let sortData = FormatTableSort({
					column,
					prop,
					order
				});
				// this.filterData.orderBy = sortData.orderBy.replace('serch_sku','sku');
				this.filterData.orderBy = sortData.orderBy;
				this.filterData.sortAsc = sortData.sortAsc;
				this.getPageData();
			},

			//分页的筛选项数据
			pageFilterData() {
				
				let deliveryTimeArr = this.filterData.deliveryTimeArr;
				let deliveryTimeStart = '';
				let deliveryTimeEnd = '';
				if (deliveryTimeArr && deliveryTimeArr.length == 2) {
					deliveryTimeStart = deliveryTimeArr[0];
					deliveryTimeEnd = deliveryTimeArr[1];
				}
				
				return {
					"sortAsc": this.filterData.sortAsc,
					"orderBy": this.filterData.orderBy,

					status: this.filterData.status ? this.filterData.status : null,
					// userId: userId,
					whNo: this.filterData.whNo ? this.filterData.whNo : null,
					
					deliveryTimeStart: deliveryTimeStart? deliveryTimeStart : null,
					deliveryTimeEnd: deliveryTimeEnd? deliveryTimeEnd : null,
					
					keyword: this.filterData.keyword ? this.filterData.keyword : null,
				};
			},

			//请求分页数据
			getPageData() {
				this.loading_load = true;
				// console.log('filterData1',this.pageFilterData());
				let filterData = Object.assign({
					offset: (this.pagination.current_page - 1) * this.pagination.page_size, //当前页
					limit: this.pagination.page_size, //当前页显示数目
				}, this.pageFilterData())

				this.$http
					.put(this.$urlConfig.WhUnclaimedGoodsSelfPageList, filterData)
					.then(({
						data
					}) => {
						this.loading_load = false;
						if (200 == data.code) {
							let rowData = data.rows;
							//表格显示数据
							this.tableData = rowData;
							//当前数据总条数
							this.pagination.total = parseInt(data.total);
							//当前页数
						} else {
							this.$message.warning(data.msg ? data.msg : this.$t('i18nn_a1761c0e88af75ff'));
						}
					})
					.catch(error => {
						// console.log(error);
						// console.log('列表，请求失败');
						this.$message.error(this.$t('hytxs0000030'));
						this.loading_load = false;
					});
			},

			//提交信息
			postData(url, formData, callBack, type) {
				this.loading = true;
				let httpPro = null;
				if ('delete' == type) {
					httpPro = this.$http.delete(url, {})
				} else {
					httpPro = this.$http.put(url, formData)
				}
				httpPro.then(({
						data
					}) => {
						console.log(this.$t('i18nn_bc868e024b80d2e3'));
						console.log(data);

						this.loading = false;
						if (200 == data.code) {

							callBack();
						} else {
							if (!data.msg) {
								data.msg = this.$t('dbe331ab679cd67f');
							}
							this.$alert(data.msg, this.$t('cc62f4bf31d661e3'), {
								type: 'warning'
							});
						}
					})
					.catch(error => {
						console.log(error);
						console.log(this.$t('i18nn_a7d2e953195a5588'));
						this.loading = false;
						this.$alert(this.$t('33bae59bb784a109'), this.$t('cc62f4bf31d661e3'), {
							type: 'warning'
						});
					});
			},

		}
	};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">

</style>