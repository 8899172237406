<template>
	<!--  -->
	<!-- <el-dialog :title="$t('i18nn_0d81640498b8d063')" append-to-body :close-on-click-modal="false" :visible.sync="dialogFile" width="1200px"
		top="0"> -->
	<el-drawer :wrapperClosable="false" :title="$t('i18nn_0d81640498b8d063')+(title?('-'+title):'')" append-to-body :visible.sync="dialogFile" :direction="'rtl'" size="1000px">
		<el-table ref="multipleTable" :data="tableData" stripe style="width: 100%" size="small"  :max-height="$store.state.frameConHeight1"
			v-loading="loadingUpload">
			<el-table-column type="index" fixed="left" width="50" align="center" :label="$t('Storage.tableColumn.no')">
			</el-table-column>
			<el-table-column prop="typeName" :label="$t('i18nn_184333c81babf2f1')"></el-table-column>
			<el-table-column prop="fileName" :label="$t('i18nn_ea4756bc1642e0f1')"></el-table-column>
			<el-table-column prop="updateTime" :label="$t('i18nn_fdc34fd7121f9c48')"></el-table-column>
			<el-table-column :label="$t('i18nn_2674282277c3714e')" width="130">
				<template slot-scope="scope">
					<el-image style="width: 100px; height: 100px" :z-index="9999" :fit="'contain'" :src="scope.row.url"
						:preview-src-list="tableData.map(itemPre=> { return itemPre.url})">
						<div slot="error" class="image-slot">
						  <i class="el-icon-document"></i>
						</div>
					</el-image>
				</template>
			</el-table-column>
			
			
			<el-table-column prop="url" :label="$t('i18nn_d54012286fece209')" show-overflow-tooltip>
				<template slot-scope="scope">
					<a :href="scope.row.url" target="_blank">{{ scope.row.url }}</a>
				</template>
			</el-table-column>
			
		</el-table>

		<!-- <div slot="footer" class="dialog-footer"> -->
			<!-- <el-button type="primary" plain @click="dialogFile = false">{{$t('i18nn_4e9fc68608c60999')}}</el-button> -->
			<!-- <el-button type="warning" plain icon="el-icon-refresh" @click="ChangeSelOrderRateExpressData()">{{$t('i18nn_a9e1d1c2c5eb6525')}}</el-button> -->
		<!-- </div> -->
	</el-drawer>
	<!-- </el-dialog> -->
	
</template>
<script>
	export default {
		props: {
			openTime: {},
			row: {},
			title:{},
			typeCode:{}
		},
		components: {},
		data() {
			return {
				dialogFile: false,
				loadingUpload: false,
				loading: false,
				// filterData: {
				// 	id: ''
				// },
				loading_load: false,
				tableData: [],
			};
		},
		watch: {
			openTime: function(newVal, oldVal) {
				console.log('openTime');
				this.initData();
				//初始化
				this.dialogFile = true;
			}
		},
		//创建时
		created() {

		},
		//编译挂载前
		mounted() {

		},
		methods: {
			initData() {

				this.tableData = [];
				this.getListData();
			},

			//请求分页数据
			getListData() {
				this.loadingUpload = true;
				this.$http
					.get(this.$urlConfig.WhFileUploadList + '/' + this.row.id + '/'+this.typeCode, {})
					.then(({
						data
					}) => {
						console.log(this.$t('i18nn_4195755cd53e871a'));
						console.log(data);
						this.loadingUpload = false;
						if (200 == data.code) {
							this.tableData = data.rows;
						} else {
							this.$alert(this.$t('tips.queryErrorFailed'), this.$t('tips.tipsTitle'), {
								type: 'warning'
							});
						}
					})
					.catch(error => {
						console.log(error);
						this.loadingUpload = false;
						this.$alert(this.$t('tips.queryRequestFailed'), this.$t('tips.tipsTitle'), {
							type: 'warning'
						});
					});
			},
		}
	};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">

</style>
